// ** React Imports
import { Fragment } from "react";

// ** Third Party Components

// ** Reactstrap Components
import { InputGroup, InputGroupText, Label } from "reactstrap";
import InputMask from "react-input-mask";
const options = {
  phone: true,
  phoneRegionCode: "sa",
  numeral: true,
  numeralDecimalScale: 8,
};

const PhoneMask = ({ value, onChange, className, icon }) => {
  return (
    <Fragment>
      <div
        className={
          className ? "input-group justify-content-center" : "input-group"
        }
        dir="ltr"
      >
        <div className={`input-group-prepend `}>
          <span
            className={
              className
                ? "text-white input-group-text bg-transparent"
                : "input-group-text "
            }
            style={{ borderRadius: 10 }}
          >
            {icon ? <span style={{ marginRight: 5 }}>{icon}</span> : null}
            SA (+966)
          </span>
        </div>
        <Input
          icon={icon}
          onChange={onChange}
          value={value}
          dir="ltr"
          className={className}
        />
      </div>
    </Fragment>
  );
};

export default PhoneMask;

const Input = (props) => (
  <InputMask
    mask="99 999 9999"
    value={props.value}
    onChange={props.onChange}
    dir="ltr"
    style={{
      borderRadius: props.icon ? 10 : null,
    }}
  >
    {(inputProps) => (
      <input
        {...inputProps}
        type="tel"
        className={
          (props.className && "bg-transparent text-white") || "form-control"
        }
        dir="ltr"
        id="inputmask"
        style={{ borderLeft: "none", marginLeft: -10, borderRadius: 10 }}
      />
    )}
  </InputMask>
);
