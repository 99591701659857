import { Singleton, Resources } from "../Resource";
import { SC } from "../Api/serverCall";
import startCase from "lodash/startCase";
import get from "lodash/get";
import moment from "moment";
class Service extends Resources {
  route = "medicationDemand";
  routes = {
    find: "",
    create: "/create",
    show: "/show",
    update: "/update",
    delete: "/delete",
    clusters: "nupco/clusters",
    status: "medicationDemand/status",
    phcMed: "medicationDemand/phcMed",
  };

  constructor() {
    super(arguments);
  }

  getMedications() {
    return SC.getCall({
      url: this.routes.phcMed,
    });
  }

  clusters(regionId) {
    return SC.getCall({
      url: this.routes.clusters,
      params: { regionId },
    });
  }

  statusUpdate(id, data) {
    return SC.putCall({
      url: this.routes.status + `/${id}`,
      data,
    });
  }

  getHeaders(obj) {
    if (obj) {
      return [
        { title: "User", dataIndex: "user" },
        { title: "Status", dataIndex: "status_of_the_submission" },
        { title: "Reject Message", dataIndex: "reject_message" },
        { title: "Region", dataIndex: "region" },
        { title: "Cluster", dataIndex: "cluster" },

        {
          title: startCase("facility_name_english"),
          dataIndex: "facility_name_english",
        },
        {
          title: startCase("facility_name_arabic"),
          dataIndex: "facility_name_arabic",
        },
        { title: startCase("city"), dataIndex: "city" },

        { title: startCase("facility_type"), dataIndex: "facility_type" },
        {
          title: startCase("facility_sub_type"),
          dataIndex: "facility_sub_type",
        },
        { title: startCase("moh_id"), dataIndex: "moh_id" },
        { title: startCase("wasfaty_code"), dataIndex: "wasfaty_code" },
        {
          title: startCase("nhic_organization_id"),
          dataIndex: "nhic_organization_id",
        },
        { title: startCase("longitude"), dataIndex: "longitude" },

        { title: startCase("name"), dataIndex: "name" },
        { title: startCase("moh_code"), dataIndex: "moh_code" },
        { title: startCase("nupco_id"), dataIndex: "nupco_id" },
        { title: startCase("medication_need"), dataIndex: "medication_need" },
        { title: startCase("quantity"), dataIndex: "quantity" },
        { title: startCase("reason"), dataIndex: "reason" },
        { title: startCase("other_reason"), dataIndex: "other_reason" },

        { title: "Created At", dataIndex: "created_at" },
      ];
    }

    return [];
  }

  mapExportData(data) {
    try {
      data = data
        .map((item) => {
          item.region = get(item, "region.name", "");
          item.cluster = get(item, "cluster.name", "");
          item.user = get(item, "user.name", "");

          let status_of_the_submission = "Pending";
          item.reject_message = item.message;
          if (!item.active && item.message) {
            status_of_the_submission = "Rejected";
          }

          if (item.active) {
            status_of_the_submission = "Approved";
            item.reject_message = "";
          }

          if (item.site) {
            item.city = item.site.city;
            item.facility_name_english = item.site.phc_name_eng;
            item.facility_name_arabic = item.site.phc_name_ar;
            item.facility_type = item.site.facilityType;
            item.facility_sub_type = item.site.facilitySubType;
            item.moh_id = item.site.moh_id;
            item.nhic_organization_id = item.site.nhic_organization_id;
            item.longitude = item.site.longitude;
            item.latitude = item.site.latitude;
          }

          item.status_of_the_submission = status_of_the_submission;
          item.nupco_users = item.medication_items.map((nupcoUser) => {
            delete nupcoUser.created_at;
            delete nupcoUser.clientX;
            delete nupcoUser.clientY;
            return nupcoUser;
          });
          item.created_at = moment(item.created_at).format("DD-MM-YYYY h:mm A");

          return [item, ...item.nupco_users];
        })
        .flatMap((i) => i);
    } catch (error) {
      console.log("Nupco export error: ", error);
    }

    return {
      headers: this.getHeaders(data[0]),
      data,
    };
  }
}

export default Singleton(Service);
