import { useState } from "react";
import ReactPaginate from "react-paginate";
import './style.scss'
import { ChevronLeftPagination, ChevronRightPagination, DoubleChevronLeft, DoubleChevronRight } from "../../../../assets/wasfaty/SVG";

const PaginationComponent = ({
  pagination,
  handlePageChange,
  isStopPaginationFirstCall,
}) => {
  const [initialPageChange, setInitialPageChange] = useState(true);

  const _handlePageChange = (selectedPage) => {
    if (initialPageChange && isStopPaginationFirstCall) {
      setInitialPageChange(false);
      return;
    }
    const paginationObj = pagination;
    paginationObj.selected = selectedPage.selected;
    handlePageChange(paginationObj);
  };

  const Previous = () => (
    <span>
      {ChevronLeftPagination}
    </span>
  );

  const Next = () => (
    <span>
      {ChevronRightPagination}
    </span>
  );

  const goToFirstPage = () => {
    const paginationObj = pagination;
    paginationObj.page = 0;
    paginationObj.selected = 0;
    handlePageChange(paginationObj);
  };

  const goToLastPage = () => {
    const paginationObj = pagination;
    paginationObj.selected = pagination.pageCount - 1;
    handlePageChange(paginationObj);
  };

  return (
    <div className="pagination-container">
      <div className="pagination-icons">
        <div className="double_chevron_left" onClick={goToFirstPage}>
          {DoubleChevronLeft}
        </div>
        <ReactPaginate
          initialPage={pagination.page - 1}
          forcePage={pagination.page - 1}
          onPageChange={_handlePageChange}
          pageCount={pagination.pageCount}
          breakLabel="..."
          nextLabel={<Next />}
          previousLabel={<Previous />}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          activeClassName="active"
          pageClassName="page-item"
          breakClassName="page-item"
          nextLinkClassName="page-link"
          pageLinkClassName="page-link"
          breakLinkClassName="page-link"
          // nextClassName="page-item next"
          // previousClassName="page-item prev"
          previousLinkClassName="page-link"
          containerClassName={`
            pagination react-paginate pagination-sm justify-content-center mt-3`}
          renderOnZeroPageCount={null}
        />
        <div className="double_chevron_right" onClick={goToLastPage}>
          {DoubleChevronRight}
        </div>
      </div>
    </div>
  );
};

export default PaginationComponent;
