import { Singleton, Resources } from "../Resource";
import { SC } from "../Api/serverCall";
import startCase from "lodash/startCase";
import get from "lodash/get";
import XLSX from "xlsx";

import { formFields } from "../Models/InspectionModal";
import moment from "moment";
class Service extends Resources {
  route = "medicationAvailable";
  routes = {
    find: "",
    create: "/create",
    show: "/show",
    update: "/update",
    delete: "/delete",
  };

  medicine = null;

  constructor() {
    super(arguments);
  }

  getMedicines = async (regionId) => {
    try {
      const res = await SC.getCall({
        url: "all-medicines",
        params: { regionId },
      });
      let medicine = res?.data?.data?.map((item) => {
        return { ...item, label: item.name, value: item._id };
      });

      if (medicine?.length) {
        return medicine;
      }

      throw res;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  getAllMedicines = async (type) => {
    try {
      const res = await SC.getCall({
        url: "medicines-with-version",
        params: { type },
      });
      let medicine = res?.data?.data?.map((item) => {
        return { ...item, label: item.name, value: item._id };
      });

      if (medicine?.length) {
        return medicine;
      }

      throw res;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  getSelectedMedicines = async (regionId) => {
    try {
      const res = await SC.getCall({
        url: "show-selected",
        params: { regionId },
      });
      let medicine = res?.data?.data?.map((item) => {
        return { ...item, label: item.name, value: item._id };
      });

      if (medicine?.length) {
        return medicine;
      }

      throw res;
    } catch (error) {
      console.log(err);
      return [ƒ];
    }
  };

  getHeaders() {
    return [
      { title: "Report Number", dataIndex: "reportNumber" },
      { title: "Region", dataIndex: "region" },
      { title: "Pharmacy", dataIndex: "site" },
      { title: "License", dataIndex: "license" },
      { title: "User", dataIndex: "user" },
      { title: startCase("longitude"), dataIndex: "longitude" },
      { title: startCase("latitude"), dataIndex: "latitude" },
      { title: startCase("phone"), dataIndex: "phone" },
      { title: startCase("phone"), dataIndex: "phone" },

      { title: startCase("owner_name"), dataIndex: "owner_name" },
      {
        title: startCase("name_of_the_institution"),
        dataIndex: "name_of_the_institution",
      },
      { title: startCase("institution_email"), dataIndex: "institution_email" },
      {
        title: startCase("facility_license_number"),
        dataIndex: "facility_license_number",
      },

      {
        title: startCase("license_issue_date"),
        dataIndex: "license_issue_date",
      },
      {
        title: startCase("license_expiry_date"),
        dataIndex: "license_expiry_date",
      },
      { title: startCase("Medicines"), dataIndex: "medicine" },
      { title: startCase("created_at"), dataIndex: "created_at" },
    ];
  }

  mapExportData(data, _auditMethod = "") {
    data = data.map((item) => {
      item.site = (item.site?._id ? item.site : item.obligation_site) || {};

      let name_en = item.site?.name;
      let name_ar = item.site?.name_ar;
      let name = name_ar || name_en || "N/A";
      if (name_en && name_ar) {
        name = name_en + " - " + name_ar;
      }

      item.license = item?.site?.license || item?.site?.licenseNumber || "N/A";
      item.site = name;

      item.reportNumber = get(item, "reportNumber", "");
      item.region = get(item, "region.name", "");

      item.user = get(item, "user.name", "");

      item.owner_name = get(item, "owner_name", "");
      item.name_of_the_institution = get(item, "name_of_the_institution", "");
      item.institution_email = get(item, "institution_email", "");
      item.facility_license_number = get(item, "facility_license_number", "");

      item.medicine = get(item, "medicine", "")
        .map((item) =>
          Object.entries(item)
            .map((inerItem) => {
              if (inerItem[0] == "medicine")
                return `${inerItem[0]} : ${inerItem[1]?.name}`;
              return `${inerItem[0]} : ${inerItem[1]}`;
            })
            .join(",")
        )
        .join("|");

      item.created_at = item?.created_at
        ? moment(item?.created_at).format("lll")
        : "";
      item.license_expiry_date = item?.license_expiry_date
        ? moment(item?.license_expiry_date).format("lll")
        : "";
      item.license_issue_date = item?.license_issue_date
        ? moment(item?.license_issue_date).format("lll")
        : "";
      item.updated_at = item?.updated_at
        ? moment(item?.updated_at).format("lll")
        : "";

      return item;
    });
    try {
      if (_auditMethod !== "All") {
        data = data.filter(
          ({ auditMethod }) =>
            (auditMethod + "").toLowerCase().trim() ===
            _auditMethod.toLowerCase().trim()
        );
      }
    } catch (error) {}

    return {
      headers: this.getHeaders(),
      data,
    };
  }
}
const MedicationAvailabilityServices = new Service();
export default MedicationAvailabilityServices;
