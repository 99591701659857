import { Singleton, Resources } from "../Resource";
import { SC } from "../Api/serverCall";

import startCase from "lodash/startCase";
import get from "lodash/get";
import moment from "moment";
class Service extends Resources {
  route = "siteAudit";

  constructor() {
    super(arguments);
  }

  getDashboard(params) {
    return SC.getCall({
      url: "obligation-dashboard",
      params,
    });
  }

  getSiteAuditMedicine(params) {
    return SC.getCall({
      url: "get-siteAudit-medicine",
      params,
    });
  }

  getExport(params) {
    return SC.getCall({
      url: "siteAudit-export",
      params,
    });
  }

  getHeaders() {
    return [
      { title: "Region", dataIndex: "region" },
      { title: "Pharmacy", dataIndex: "site" },
      { title: "Pharmacy License", dataIndex: "license" },
      { title: "User", dataIndex: "user" },
      { title: startCase("longitude"), dataIndex: "longitude" },
      { title: startCase("latitude"), dataIndex: "latitude" },
      {
        title: startCase("date_and_time_of_visit"),
        dataIndex: "date_and_time_of_visit",
      },
      { title: startCase("it_infrastructure"), dataIndex: "it_infrastructure" },
      { title: startCase("wasfaty_sticker"), dataIndex: "wasfaty_sticker" },
      { title: startCase("unified_number"), dataIndex: "unified_number" },
      {
        title: startCase("crowd_in_the_pharmacy"),
        dataIndex: "crowd_in_the_pharmacy",
      },
      { title: startCase("size_and_crowding"), dataIndex: "size_and_crowding" },
      {
        title: startCase("nearest_subscriber_health_care"),
        dataIndex: "nearest_subscriber_health_care",
      },
      {
        title: startCase("working_time_agreed"),
        dataIndex: "working_time_agreed",
      },
      {
        title: startCase("storing_in_refrigerator"),
        dataIndex: "storing_in_refrigerator",
      },
      {
        title: startCase("same_pharmacist_who_dispensed"),
        dataIndex: "same_pharmacist_who_dispensed",
      },
      {
        title: startCase("pharmacist_access_to_wasfaty_system"),
        dataIndex: "pharmacist_access_to_wasfaty_system",
      },
      {
        title: startCase("pharmacist_knowledge_of_the_system"),
        dataIndex: "pharmacist_knowledge_of_the_system",
      },
      {
        title: startCase("pharmacists_procedures"),
        dataIndex: "pharmacists_procedures",
      },
      {
        title: startCase("dispensing_instructions"),
        dataIndex: "dispensing_instructions",
      },
      {
        title: startCase("covering_therapeutic_period"),
        dataIndex: "covering_therapeutic_period",
      },
      {
        title: startCase("waiting_time_dispense_process"),
        dataIndex: "waiting_time_dispense_process",
      },
      {
        title: startCase("pharmacists_behavior"),
        dataIndex: "pharmacists_behavior",
      },
      {
        title: startCase("wasfaty_dispensing_start_time"),
        dataIndex: "wasfaty_dispensing_start_time",
      },
      {
        title: startCase("wasfaty_dispensing_end_time"),
        dataIndex: "wasfaty_dispensing_end_time",
      },
      {
        title: startCase("prescription_available"),
        dataIndex: "prescription_available",
      },
      {
        title: startCase("prescription_available_no"),
        dataIndex: "prescription_available_no",
      },
      {
        title: startCase("pers_aval_no_other"),
        dataIndex: "pers_aval_no_other",
      },
      {
        title: startCase("medication_not_available"),
        dataIndex: "medication_not_available",
      },
      {
        title: startCase("medications_available"),
        dataIndex: "medications_available",
      },
      {
        title: startCase("medications_available"),
        dataIndex: "medications_available",
      },
      {
        title: startCase("medication_dispensed_not"),
        dataIndex: "medication_dispensed_not",
      },
      {
        title: startCase("medication_dispensed_not_others"),
        dataIndex: "medication_dispensed_not_others",
      },
      {
        title: startCase("instruction_label_available"),
        dataIndex: "instruction_label_available",
      },
      {
        title: startCase("dataIsBeingEnteredAsPerTraining"),
        dataIndex: "dataIsBeingEnteredAsPerTraining",
      },
      {
        title: startCase("instruction_label_eng_arab"),
        dataIndex: "instruction_label_eng_arab",
      },
      {
        title: startCase("pharmacist_counseling_customer"),
        dataIndex: "pharmacist_counseling_customer",
      },
      {
        title: startCase("tat_entrance_to_handling_prescription"),
        dataIndex: "tat_entrance_to_handling_prescription",
      },
      {
        title: startCase("tat_handling_prescription_to_obtaining_medication"),
        dataIndex: "tat_handling_prescription_to_obtaining_medication",
      },
      {
        title: startCase("waiting_area_available"),
        dataIndex: "waiting_area_available",
      },
      {
        title: startCase("pharmacy_attitude"),
        dataIndex: "pharmacy_attitude",
      },
      { title: startCase("look_feel"), dataIndex: "look_feel" },
      {
        title: startCase("wasfaty_service_available"),
        dataIndex: "wasfaty_service_available",
      },
      {
        title: startCase("pharmacy_connected_to_rased_system"),
        dataIndex: "pharmacy_connected_to_rased_system",
      },
      {
        title: startCase("pharmacist_provide_complete_medication"),
        dataIndex: "pharmacist_provide_complete_medication",
      },
      {
        title: startCase("customer_receive_service_evaluation"),
        dataIndex: "customer_receive_service_evaluation",
      },
      { title: startCase("comments"), dataIndex: "comments" },
      { title: startCase("phone"), dataIndex: "phone" },
      { title: startCase("auditMethod"), dataIndex: "auditMethod" },
      { title: startCase("prescription_id"), dataIndex: "prescription_id" },
      {
        title: startCase("is_the_rassd_system_active_at_the_pharmacy"),
        dataIndex: "is_the_rassd_system_active_at_the_pharmacy",
      },
      {
        title: startCase("is_the_pharmacy_available"),
        dataIndex: "is_the_pharmacy_available",
      },
      { title: startCase("created_at"), dataIndex: "created_at" },
    ];
  }

  getHeadersObligation() {
    return [
      { title: startCase("auditMethod"), dataIndex: "auditMethod" },
      { title: "Region", dataIndex: "region" },
      { title: "Pharmacy", dataIndex: "site" },
      { title: "License", dataIndex: "license" },
      { title: "User", dataIndex: "user" },
      { title: startCase("phone"), dataIndex: "phone" },
      { title: startCase("longitude"), dataIndex: "longitude" },
      { title: startCase("latitude"), dataIndex: "latitude" },
      {
        title: startCase("is_the_pharmacy_available"),
        dataIndex: "is_the_pharmacy_available",
      },

      {
        title: startCase("wasfaty_service_available"),
        dataIndex: "wasfaty_service_available",
      },
      {
        title: startCase("pharmacy_connected_to_rased_system"),
        dataIndex: "pharmacy_connected_to_rased_system",
      },
      {
        title: startCase("is_the_rassd_system_active_at_the_pharmacy"),
        dataIndex: "is_the_rassd_system_active_at_the_pharmacy",
      },
      {
        title: startCase("medication_instruction_label"),
        dataIndex: "medication_instruction_label",
      },

      { title: startCase("Overall Evaluation"), dataIndex: "look_feel" },
      { title: startCase("comments"), dataIndex: "comments" },
      { title: startCase("created_at"), dataIndex: "created_at" },
    ];
  }

  mapExportData(data, _auditMethod = "") {
    data = data.map((item) => {
      item.region = get(item, "region.name", "");

      item.site = item.site || item.obligation_site || {};
      let name_en = item.site?.name;
      let name_ar = item.site?.name_ar;
      let name = name_ar || name_en || "N/A";
      if (name_en && name_ar) {
        name = name_en + " - " + name_ar;
      }

      item.license = item?.site?.license || item?.site?.licenseNumber || "N/A";
      item.site = name;

      item.user = get(item, "user.name", "");
      item.created_at = item?.created_at
        ? moment(item?.created_at).format("lll")
        : "";
      return item;
    });
    try {
      if (_auditMethod !== "All") {
        data = data.filter(
          ({ auditMethod }) =>
            (auditMethod + "").toLowerCase().trim() ===
            _auditMethod.toLowerCase().trim()
        );
      }
    } catch (error) {}

    return {
      headers:
        _auditMethod === "Obligation Department"
          ? this.getHeadersObligation()
          : this.getHeaders(),
      data,
    };
  }
}

export default Singleton(Service);
