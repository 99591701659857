import { Resources } from "../Resource";
import { SC } from "../Api/serverCall";

class Service extends Resources {
  route = "RiskCompliance";
  routes = {
    find: "",
    create: "/create",
    show: "/show",
    update: "/update",
    delete: "/delete",
  };

  // medicine = null;

  constructor() {
    super(arguments);
  }

  getCategories = () => {
    return SC.getCall({ url: "RiskCompliance/categories" });
  };
}
const RiskAndComplianceService = new Service();
export default RiskAndComplianceService;
