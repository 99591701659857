import React from "react";
import brandLogo from "../assets/brandLogo.svg";
import { headPhones } from "../assets/svg";
import { IntlService } from "../../wasfaty/services";
import { UncontrolledTooltip } from "reactstrap";

function Header() {
  return (
    <div className="d-flex justify-content-between main-header justify-items-center">
      <img src={brandLogo} />{" "}
      <UncontrolledTooltip target="support">
        {IntlService.m("Coming Soon!")}
      </UncontrolledTooltip>
      <div className="d-flex align-items-center">
        <span>{headPhones} </span>{" "}
        <p id="support" className="supportLink  cursor-pointer">
          {IntlService.m("Get Support")}
        </p>
      </div>
    </div>
  );
}

export default Header;
