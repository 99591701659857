// ** React Imports
import { lazy } from "react";
import ForgotPasswordV1 from "../../views/AuthScreens/ForgotPassword";
import Inbox from "../../views/AuthScreens/imbox";
import Login from "../../views/AuthScreens/Login";
import ResetPasswordV1 from "../../views/AuthScreens/ResetPassword";
import ConfirmPassword from "../../views/AuthScreens/ConfirmPassword";
import InstituteNumber from "../../views/AuthScreens/InstitutionNumber";

const Page404 = lazy(() => import("../../views/pages/Other/Page404"));

const AuthenticationRoutes = [
  {
    path: "/login",
    element: <Login />,
    route: "",
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true,
    },
    isAuth: true,
  },
  {
    path: "/forgot-password",
    element: <ForgotPasswordV1 />,
    route: "",
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true,
    },
    isAuth: true,
  },
  {
    path: "/institute-register",
    element: <InstituteNumber />,
    route: "",
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true,
    },
    isAuth: true,
  },
  {
    path: "/check-reset",
    element: <Inbox />,
    route: "",
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: false,
    },
    isAuth: true,
  },

  {
    path: "/reset-password/:token/:email",
    element: <ResetPasswordV1 />,
    route: "",
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true,
    },
    isAuth: true,
  },
  {
    path: "/institute-set-password/:token/:email",
    element: <ConfirmPassword />,
    route: "",
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true,
    },
    isAuth: true,
  },

  {
    element: <Page404 />,
    path: "*",
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: false,
    },
    isAuth: true,
  },
];

export default AuthenticationRoutes;
