import { Singleton, Resources } from "../Resource";
import { SC } from "../Api/serverCall";
import moment from "moment";
import get from "lodash/get";

class Service extends Resources {
  route = "site";
  byRegion = "get-form-site";
  byInspectionRegion = "get-inspection-site";
  inspectionSites = "inspection-site";
  formNupcoSite = "get-form-nupco_site";
  allSites = "get-all-sites";
  getSite = "check-all-sites";
  siteSearch = "get-form-siteSearch";
  allDualSites = "dual-sites";
  allSitesByType = "dualSites";

  routes = {
    export: "site-index",
  };

  constructor() {
    super(arguments);
  }

  getByRegion(regionId) {
    return SC.getCall({
      url: this.byRegion,
      params: { regionId },
    });
  }

  getSiteById(id) {
    return SC.getCall({
      url: this.getSite + `/${id}`,
    });
  }

  getAllSitesByRegion(regionId) {
    return SC.getCall({
      url: this.allSites,
      params: { regionId },
    });
  }

  getFormNupcoSite(clusterId) {
    return SC.getCall({
      url: this.formNupcoSite,
      params: { clusterId },
    });
  }

  async getByInspectionRegion(regionId) {
    return SC.getCall({
      url: this.byInspectionRegion,
      params: { regionId },
    });
  }

  async getInspectionSites(params) {
    return SC.getCall({
      url: this.inspectionSites,
      params,
    });
  }
  async getWasfatySites(params) {
    return SC.getCall({
      url: this.siteSearch,
      params,
    });
  }

  async getSites(params, type) {
    switch (type) {
      case "inspection":
        return this.getInspectionSites(params);
      case "wasfaty":
        return this.getWasfatySites(params);
    }
  }

  getAllSites(params) {
    return SC.getCall({
      url: this.allDualSites,
      params,
    });
  }

  getSitesByType(params) {
    return SC.getCall({
      url: this.allSitesByType,
      params,
    });
  }

  getSitesByClusterIDs = async (params) => {
    try {
      let res = await SC.getCall({
        url: this.allSitesByType,
        params,
      });
      let _data = get(res, "data.data");
      let totalSites = get(res, "data.total_sites");
      if (_data?.length) {
        const sites = _data.map((item) => ({
          ...item,
          label: item.name,
          value: item._id,
        }));
        return {
          sites,
          totalSites,
        };
      }
      return [];
    } catch (e) {
      return [];
    }
  };

  getHeaders() {
    return [
      { title: "Name", dataIndex: "name" },
      { title: "Site Type", dataIndex: "siteType" },
      { title: "Phc Site", dataIndex: "phcSite" },
      { title: "Region", dataIndex: "region" },
      { title: "Created at", dataIndex: "created_at" },
    ];
  }

  mapExportData({ data = [] }) {
    data = data.map((item) => {
      item.region = item.region?.name;
      item.created_at = moment(item.created_at).format("DD-MM-YYYY h:mm A");
      return item;
    });

    return {
      headers: this.getHeaders(),
      data,
    };
  }
}

export default Singleton(Service);
