import { Singleton } from "../Resource";
// const AWS = require("aws-sdk");
import axios from "axios";

const config = {
  bucketName: "efficax-obligation",
  endpoint: "https://micro-helper.ascend.com.sa/minio/upload",
};

class Service {
  constructor() { }

  // uploadFile(file, callBackS3, progressCB) {
  //   if (file) {
  //     console.log("Upload file");
  //     const s3 = new AWS.S3({
  //       ...config,
  //       s3ForcePathStyle: true,
  //       signatureVersion: "v4",
  //       ContentDisposition: "attachment;filename=" + file?.name,
  //     });
  //     const params = {
  //       Bucket: config.bucketName,
  //       Key: `${Date.now()}_${file?.name}`,
  //       Body: file,
  //       ACL: "public-read",
  //     };

  //     return s3
  //       .upload(params, function (err, data) {
  //         console.log(data, "---")
  //         if (data) callBackS3(data);
  //       })

  //       .on("httpUploadProgress", function (progress) {
  //         let progressPercentage = Math.round(
  //           (progress.loaded / progress.total) * 100
  //         );
  //         progressCB(progressPercentage);
  //       });
  //   }
  // }
  async uploadFile(file, callBackS3, progressCB) {
    if (file) {
      const formData = new FormData();
      formData.append(`project`, config.bucketName);
      formData.append(`file`, file);

      let Key = `${Date.now()}_${file?.name}`;
      try {
        let res = await axios.post(config.endpoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: progressEvent => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            // You can use progressCB to update the progress wherever needed
            progressCB(progress);
          },
        });
        // If the upload is successful, you can execute callBackS3 or handle the response here

        res = {
          "Location": res.data.paths,
          "key": Key,
          "Key": Key

        }
        callBackS3(res);

      } catch (error) {
        // Handle upload error
      }
    }
  }
}

export default Singleton(Service);
