// ** Icons Import

import {
  Compliance,
  GeneralHospitalIcon,
  MedicineAvailbilityIcon,
  SelfAssessmentIcon,
  VirtualInspection,
  Medicine,
  Modules,
  Regions,
  Users,
  Sites,
  Forms,
  Settings,
  languageIco,
  InspectionEngine,
  Cluster,
  headphones,
  SelfAssessment,
  riskComplainceIcon,
} from "../../assets/wasfaty/SVG";

const navigationLinks = [
  {
    id: "Compliance-Dashboard",
    slug: "Compliance Dashboard",
    title: "Compliance Dashboard",
    text: "Compliance Dashboard",

    navLink: "/Compliance-Dashboard",
    icon: () => Compliance,
    skipForQuickAdd: true,
  },
  // {
  //   id: "Hajj-Inspection",
  //   slug: "Hajj Inspection",
  //   title: "Hajj Inspection",
  //   icon: () => Forms,
  //   forRegions: ["MADINA", "MAKKAH"],
  //   onlyFor: [
  //     "admin",
  //     "regional representatives",
  //     "agent",
  //     "facility",
  //     "cluster manager",
  //     "pharmacist",
  //     "supervisor",
  //     "expert",
  //   ],
  //   children: [
  //     {
  //       id: "inspection",
  //       slug: "Regular-Visit",
  //       title: "Regular Visit Form",
  //       navLink: "/Regular-Visit",
  //       icon: () => RegularVisitIcon,
  //     },
  //     {
  //       id: "private_hospital",
  //       slug: "Private-Hospital",
  //       title: "Private Hospital Form",
  //       icon: () => PrivateHoispitalIcon,
  //       navLink: "/Private-Hospital",
  //     },
  //   ],
  // },
  {
    id: "forms",
    slug: "Forms",
    title: "Forms",
    icon: () => Forms,

    children: [
      {
        id: "selfAssessment",
        slug: "Self-Assessment",
        title: "Self Assessment Form",
        navLink: "/Self-Assessment",
        icon: () => SelfAssessmentIcon,
      },
    ],
  },
  {
    id: "tasks",
    slug: "My Tasks",
    title: "My Tasks",
    icon: () => GeneralHospitalIcon,
    navLink: "/My-Task",
    onlyFor: ["agent", "expert"],
    skipForQuickAdd: true,
  },

  {
    id: "tasks",
    slug: "My Medical Error Tasks",
    title: "My Medical Error Tasks",
    icon: () => GeneralHospitalIcon,
    navLink: "/My-Medical-Error-Tasks",
    onlyFor: ["agent", "expert"],
    skipForQuickAdd: true,
  },

  {
    id: "Self-Assessment-Tasks",
    slug: "Self Assessment Tasks",
    title: "Self Assessment Tasks",
    icon: () => GeneralHospitalIcon,
    navLink: "/Self-Assessment-Tasks",
    onlyFor: [
      "cluster manager",
      "facility",
      "facility manager",
    ],
    skipForQuickAdd: true,
  },

  {
    id: "Schedule-Task-Availability",
    slug: "Schedule Tele-Inspection",
    title: "Schedule Tele-Inspection",
    icon: () => GeneralHospitalIcon,
    navLink: "/Schedule-Task-Availability",
    onlyFor: ["admin", "regional representatives", "supervisor"],
  },
  {
    id: "Schedule-Medical-Error",
    slug: "Schedule Medical-Error",
    title: "Schedule Medical Error",
    icon: () => GeneralHospitalIcon,
    navLink: "/Schedule-Medical-Error",
    onlyFor: ["admin", "regional representatives", "supervisor"],
  },
  {
    id: "v-sight-1",
    slug: "My-Virtual-Sessions",
    title: "My Virtual Sessions",
    icon: () => VirtualInspection,

    navLink: "/Virtual-Sessions",
    onlyFor: ["admin", "regional representatives", "supervisor"],
    skipForQuickAdd: true,
  },
  {
    id: "scheduleSelfAssessment",
    slug: "Schedule-Self-Assessment",
    title: "Schedule Self Assessment",
    icon: () => SelfAssessment,

    navLink: "/Schedule-Self-Assessment",
    onlyFor: [
      "admin",
      "regional representatives",
    ],
    skipForQuickAdd: true,
  },
  {
    id: "Support",
    slug: "Support",
    title: "Support",
    icon: () => headphones,
    extraMenu: true,
    disabled: true,
    onlyFor: [
      "admin",
      "regional representatives",
      "agent",
      "supervisor",
      "expert",
    ],
    skipForQuickAdd: true,
  },
  {
    id: "settings",
    slug: "Settings",
    title: "Settings",
    icon: () => Settings,
    extraMenu: true,
    children: [
      {
        id: "Users",
        slug: "Users",
        title: "Users",
        text: "User",

        navLink: "/Users",
        icon: () => Users,
      },
      {
        id: "Regions",
        slug: "Regions",
        title: "Regions",
        icon: () => Regions,
        navLink: "/Regions",
      },

      {
        id: "Sites",
        slug: "Sites",
        title: "Sites",
        icon: () => Sites,

        navLink: "/Sites",
      },
      {
        id: "Clusters",
        slug: "Clusters",
        title: "Clusters",
        icon: () => Cluster,

        navLink: "/Clusters",
      },
      {
        id: "Modules",
        slug: "Modules",
        title: "Modules",
        navLink: "/Modules",
        icon: () => Modules,
      },

      {
        id: "form_builder",
        slug: "Inspection-Engine",
        title: "Inspection Engine",
        icon: () => InspectionEngine,

        navLink: "/Inspection-Engine",
      },
      {
        id: "Risk & Compliance",
        slug: "Standards",
        title: "Risk & Compliance",
        text: "Risk & Compliance",

        navLink: "/Standards",
        icon: () => riskComplainceIcon,
      },
      {
        id: "arabic",
        slug: "Translation",
        title: "Translation",
        icon: () => languageIco,
        navLink: "/Translation",
      },
    ],
  },
  // %template%
];

export default navigationLinks;
