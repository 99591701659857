import { makeAutoObservable, autorun } from "mobx";
import sortBy from "lodash/sortBy";

import { phoneValidator, getValidPhone } from "../Helper/utils";
import FileUploadModal from "./FileUploadModel";
import { ModuleService } from "../services";
import { getUserData } from "@utils";
import { steperForm } from "./AllFormNamesAndSteps";

import TaskAvailabilityModal from "./TaskAvailabilityModal";
import AuthUser from "../services/AuthService";
import { Schema } from "@formily/json-schema";
import { dateFormat } from "../Helper/DateFormate";

class DynamicFormModal {
  _id = "";
  is_inspection_engine = true;
  name = "";
  allSteps = [];
  isLoading = false;

  constructor(props) {
    this._id = props._id;
    this.name = props.name;
    makeAutoObservable(this);
  }

  setSteps = async () => {
    this.isLoading = true;
    let res = await ModuleService.getSteps(this._id);
    if (res.schema.schema) {
      this.allSteps = this.parseSteps(res.schema.schema);
    }
    this.isLoading = false;
  };
  parseSteps = (orignalSchema) => {
    orignalSchema = new Schema(orignalSchema);
    const steps = [];

    let mapProps = (s) => {
      s.mapProperties((schema, name) => {
        if (Object.keys(schema?.properties || {})) {
          mapProps(schema);
        }

        if (schema["x-component"]?.indexOf("StepPane") > -1) {
          steps.push({
            value: name,
            label: schema["x-component-props"]["title"],
            // schema,
          });
        }
      });
    };

    mapProps(orignalSchema);
    return steps;
  };

  get steps() {
    return this.allSteps;
  }
}

export class ScheduleMedicalErrorTaskModal {
  class_name = "ScheduleMedicalErrorTaskModal";
  isValidation = false;
  isSiteFetching = false;
  isSubmit = false;
  isReview = false;
  isEdit = false;
  isAllFilesUploaded = false;

  // Server fields require for payload
  _id = "";
  region_id = ""; // Region
  site_id = ""; // Site
  region = "";
  site = "";
  user = "";
  auditor_email = "";
  auditor_phone = "";
  inspectorPhone = "";
  form_name = "";
  user_id = ""; // Current Logged In user
  due_date = Date.now();
  completed_date = "";
  created_at = "";
  is_completed = "";
  tasks = "";
  updated_at = "";
  submission_id = null;

  tasks = [];

  drft = null;
  isEdit = false;
  steperForm = steperForm;
  dynamicForms = [];
  isLoadingSteps = false;
  meta_data = {};
  constructor(props) {
    this.isEdit = !!props;

    this.steperForm = steperForm;

    if (!this.isEdit) {
      this.tasks.push(new TaskAvailabilityModal(null, "inspector"));
      this.tasks.push(new TaskAvailabilityModal(null, "expert"));
      this.setUserId(getUserData());
    }

    this.getDynamicForm();

    this.init(props);
    makeAutoObservable(this);
  }

  init = (props) => {
    if (props) {
      this.setPayload(props);
      this.isAllFilesUploaded = true;
    }
  };

  setAttribute(name, value) {
    if (this.hasOwnProperty(name)) {
      this[name] = value;
    }

    autorun(() => {})();
  }

  setUserId(user) {
    this.user_id = user._id;
    this.user = user;
    this.auditor_email = user.email;
    this.auditor_phone = getValidPhone(user.phone);
  }

  setPayload(payload) {
    // console.log("payload", payload);
    if (payload) {
      const { region, site, inspectorPhone, tasks, auditor_phone } = payload;
      Object.entries(payload).forEach(([name, value]) => {
        if (name.includes("_file")) {
          this[name] = new FileUploadModal(value);
          this[name].setImages(value);
        } else {
          this.setAttribute(name, this.isDropDown(name, value));
        }
      });

      if (region) {
        this.region = { ...region, value: region._id, label: region.name };
      }

      if (site) {
        this.site = { ...site, value: site._id, label: site.name };
      }

      if (tasks?.length) {
        this.tasks = [];
        tasks.forEach((task) => {
          this.tasks.push(new TaskAvailabilityModal(task));
        });
      }

      this.inspectorPhone = getValidPhone(inspectorPhone);
      this.auditor_phone = getValidPhone(auditor_phone);
    }
  }

  get dropDownKeys() {
    return ["form_name"];
  }

  isDropDown(name, value) {
    if (this.dropDownKeys.includes(name)) {
      value = { label: value, value };
    }

    return value;
  }

  setValidation(check) {
    this.isValidation = check;
  }

  setIsSubmit(check) {
    this.isSubmit = check;
  }

  setIsReview(check) {
    this.isReview = check;
  }

  getPayload(isDraft) {
    let payload = {};
    payload.site_id = this.site?._id;
    payload.region_id = this.region?._id;
    payload.due_date = dateFormat(this.due_date);
    payload.form_name = this.form_name?.value;

    payload.meta_data = {
      form_id: this.form_name?._id,
      name: this.form_name?.name || this.form_name?.value,
      slug: this.form_name?.value,
      isDynamicForm: this.form_name?.is_inspection_engine,
    };

    console.log(this.form_name, payload.meta_data);

    payload.tasks = this.tasks.map((task) => task.getPayload());

    return payload;
  }

  getDynamicForm = () => {
    ModuleService.getDynamicForms().then((forms) => {
      this.dynamicForms.push(...forms.map((f) => new DynamicFormModal(f)));
    });
  };

  isTasksValid() {
    return this.tasks.reduce((result, task) => {
      return (
        result &&
        task.user?.email &&
        phoneValidator(task.user.phone) &&
        task.steps?.length
      );
    }, true);
  }

  addTask = (type) => {
    this.tasks.push(new TaskAvailabilityModal(null, type));
  };

  removeTask() {
    this.tasks.pop();
  }

  setFormName = async (form_name) => {
    this.isLoadingSteps = true;

    if (form_name.is_inspection_engine) {
      await form_name.setSteps();
    }
    this.form_name = form_name;

    this.clearInspectorsSteps();
    this.isLoadingSteps = false;
  };

  clearInspectorsSteps = () => {
    this.tasks.forEach((task) => task.clearSteps());
  };

  isValid = () => {
    let check =
      this.region &&
      this.site &&
      this.isTasksValid() &&
      this.form_name &&
      !this.formSteps?.length;

    return check;
  };

  get selectedSteps() {
    return this.tasks.map((task) => task.selectedSteps).flatMap((i) => i);
  }

  get formSteps() {
    if (this.form_name.is_inspection_engine) {
      return (
        this.dynamicForms
          .find((f) => f._id === this.form_name._id)
          .steps.filter((step) => !this.selectedSteps.includes(step.value)) ||
        []
      );
    }

    return Object.entries(
      ModuleService.getFormOptionsBySlug(this.form_name?.value) || {}
    )
      .map(([key]) => ({ label: key, value: key }))
      .filter((step) => !this.selectedSteps.includes(step.value));
  }

  get progress() {
    return (
      (this.tasks.filter((task) => task.is_approved).length /
        this.tasks.length) *
      100
    );
  }

  get scheduleHistory() {
    return sortBy(
      this.tasks.map((task) => task.history).flat(),
      (history) => history.created_at
    ).reverse();
  }

  get steperForms() {
    return [...ModuleService.getSteperForms(), ...this.dynamicForms];
  }

  get canUpdateDueDate() {
    if (this.is_completed) {
      return false;
    }

    return (
      AuthUser.isAdmin ||
      AuthUser.isSupervisor ||
      AuthUser.isRegionalRepresentatives
    );
  }

  get canDelete() {
    return AuthUser.isAdmin;
  }
}
