import { makeAutoObservable } from "mobx";
import {
  phoneValidator,
  emailValidator,
  getValidPhone,
  StepsAlert,
} from "../Helper/utils";
import toast from "react-hot-toast";
import Inspector from "./InspectorModel";
import FileUploadModal from "./FileUploadModel";
import { IntlService, MedicationAvailabilityServices } from "../services";
import startCase from "lodash/startCase";
import DraftModal from "./DraftModel";
import { getUserData } from "@utils";
import uniqBy from "lodash/uniqBy";
import Staff from "./Staff";
import { scrollToRequired } from "../Helper/utils";
import { ModalMixin } from "./Utility";
import MedicineModel from "./MedicineSelectionForMadicineAvailbilityModel";
import ComplianceMember from "./ComplianceMemberModel";
export const formFields = {};

const flatten = function (form) {
  return Object.entries(form)
    .map(([key, attributes]) =>
      attributes.map((item) => {
        item.parent = key;
        return item;
      })
    )
    .flatMap((attribute) => attribute);
};

export class SelfAssessmentModal {
  class_name = "SelfAssessmentModalM1";
  isValidation = false;
  isSubmit = false;
  isReview = false;
  isEdit = false;
  isSitesLoading = false;
  medicine = [];
  medicineRes = [];
  task = null;

  // Server fields require for payload
  _id = null;
  region_id = null; // Region
  site_id = null; // Site
  region = null;
  site = null;
  user = null;
  city = null;
  district_name = null;
  street_name = null;
  user_id = null; // Current Logged In user
  longitude = null; // Longitude
  latitude = null; // Latitude
  medOptActive = [];
  allMedicinesOptions = [];
  allMedicinesResOptions = [];
  medOptActiveLen = null;
  stepper = null;
  //Helper variables
  isAllFilesUploaded = false;
  isMedicineLoading = false;
  isEdit = false;
  draft = null;

  isInputModalVisiable = false;

  // complianceMembers = [];
  // compDelegatedPerson = "";
  // compIdNumber = "";
  // compIssueDate = new Date();
  // compIssuePlace = "";
  // composition = "";
  // compSignature = new ComplianceMember(null, true);
  test = null;

  constructor(props, task) {
    this.isEdit = !!props;
    // ModalMixin.setupCommonActions.call(this);
    ModalMixin.setupCommonActions.call(this);
    ModalMixin.setupFormOptions.call(this, "Self-Assessment");
    ModalMixin.setupTask.call(this, task);

    if (!this.isEdit) {
      this.complianceMembers.push(new ComplianceMember());
      this.compSignature = new ComplianceMember(null, true);
      this.draft = new DraftModal(this);

      this.getMedicinesRes();
      this.getMedicines();
    } else {
      this.init(props);
    }

    makeAutoObservable(this);
  }

  init(props) {
    if (props) {
      this.setPayload(props);
      this.isAllFilesUploaded = true;
    }
  }

  afterSetPayload = (payload) => {
    if (payload) {
      if (payload?.medicine?.length) {
        this.medicine = [];
        payload?.medicine.forEach((med) => {
          this.medicine.push(new MedicineModel(med, true));
        });
      }

      if (payload?.medicineRes?.length) {
        this.medicineRes = [];
        payload?.medicineRes.forEach((med) => {
          this.medicineRes.push(new MedicineModel(med, true));
        });
      }
    }
  };
  getMedicines = () => {
    // this.isMedicineLoading = true;
    MedicationAvailabilityServices.getAllMedicines("General Medicines").then(
      (med) => {
        this.allMedicinesOptions = uniqBy(med, (med) => med.name);
        // this.medicine = med.map((medicine) => new MedicineModel(medicine));
        // .filter((i) => i);
        if (!this.medicine.length) {
          this.addMedicine();
        }
        // this.isMedicineLoading = false;/
      }
    );
  };
  getMedicinesRes = () => {
    // this.isMedicineLoading = true;
    MedicationAvailabilityServices.getAllMedicines(
      "Respiratory Medicines"
    ).then((med) => {
      this.allMedicinesResOptions = uniqBy(med, (med) => med.name);
      // this.medicine = med.map((medicine) => new MedicineModel(medicine));
      // .filter((i) => i);

      if (!this.medicineRes.length) {
        this.addMedicineRes();
      }
      // this.isMedicineLoading = false;
    });
  };

  addMedicine = () => {
    this.medicine.push(new MedicineModel());
  };
  addMedicineRes = () => {
    this.medicineRes.push(new MedicineModel());
  };

  removeMedicine = (medicine) => {
    if (medicine) {
      this.medicine = this.medicine.filter((med) => med.__id != medicine.__id);
      return;
    }

    if (this.medicine?.length) {
      let med = this.medicine.at(-1);
      if (med.preSelected) {
        toast.error(
          IntlService.formatMessage(
            `Minimum ${this.preSelectesMedicines.length} medicine required`
          )
        );
      } else {
        this.medicine.pop();
      }
    }
  };
  removeMedicineRes = (medicine) => {
    if (medicine) {
      this.medicineRes = this.medicineRes.filter(
        (med) => med.__id != medicineRes.__id
      );
      return;
    }

    if (this.medicineRes?.length) {
      let med = this.medicineRes.at(-1);
      if (med.preSelected) {
        toast.error(
          IntlService.formatMessage(
            `Minimum ${this.preSelectesMedicines.length} medicine required`
          )
        );
      } else {
        this.medicineRes.pop();
      }
    }
  };

  resetMedicines = () => {
    this.medicine = [];
    this.addMedicine();
  };

  resetMedicinesRes = () => {
    this.medicineRes = [];
    this.addMedicineRes();
  };
  setAttribute(name, value) {
    console.log("name, value", name, value);
    if (name) {
      this[name] = value;
    }
  }

  payloadSetAttribute(name, value) {
    if (name) {
      this[name] = value;
    }
  }

  setRegion(value) {
    this.region = value;
  }

  setUserId(user) {
    this.user_id = user._id;
    this.user = user;
  }

  get dropDownKeys() {
    return [
      "pharmacy_connected_to_rased_system",
      "wasfaty_service_available",
      "is_the_pharmacy_available",
      "is_the_rassd_system_active_at_the_pharmacy",
      "medication_instruction_label",
    ];
  }

  isDropDown(name, value) {
    if (this.dropDownKeys.includes(name)) {
      value = { label: value, value };
    }

    return value;
  }

  setValidation(check) {
    this.isValidation = check;
  }

  setIsSubmit(check) {
    this.isSubmit = check;
  }

  setIsReview(check) {
    this.isReview = check;
  }

  getPayload() {
    let payload = {};

    if (this.formFields?.["identification"]) {
      payload.region_id = this.region?._id;
      payload.site_id = this.site?._id;
      payload.street_name = this.street_name;
      payload.city = this.city;
      payload.district_name = this.district_name;
      payload.longitude = this.longitude;
      payload.latitude = this.latitude;
      payload.region = this.region;
      payload.obligation_site = this.site;
      payload.user_id = this.user?._id;
    }

    if (this.formFields?.["general_medicine"]) {
      payload.medicine = this.medicine.map((Ob) => Ob?.getPayload());
    }

    if (this.formFields?.["respiratory_medicine"]) {
      payload.medicineRes = this.medicineRes.map((Ob) => Ob?.getPayload());
    }

    payload.class_name = this.class_name;
    payload.allMedicinesOptions = this.allMedicinesOptions;

    if (this.isAdditionalRemarks) {
      payload.compDelegatedPerson = this.compDelegatedPerson;
      payload.compIdNumber = this.compIdNumber;
      payload.compIssueDate = this.compIssueDate?.toISOString?.();
      payload.compIssuePlace = this.compIssuePlace;
      payload.composition = this.composition;
      payload.compSignature = this.compSignature?.signature;

      payload.complianceMembers = this.complianceMembers.map((mem) =>
        mem.getPayload()
      );
    }

    return payload;
  }

  isValid() {
    let check = true;
    if (this.formFields?.["identification"]) {
      check &&
        check &&
        !!this.region &&
        !!this.site &&
        !!this.street_name &&
        !!this.city &&
        !!this.district_name &&
        !!this.longitude &&
        !!this.latitude;
    }

    if (this.formFields?.["general_medicine"]) {
      this.medicine.reduce((res, med) => res && med.isValid(), check);
    }

    if (this.formFields?.["respiratory_medicine"]) {
      this.medicineRes.reduce((res, med) => res && med.isValid(), check);
    }

    if (this.isAdditionalRemarks) {
      check =
        check &&
        this.compDelegatedPerson &&
        this.compIdNumber &&
        this.compIssueDate &&
        this.compIssuePlace &&
        this.composition &&
        this.compSignature?.signature?.length;

      check = this?.complianceMembers?.reduce(
        (result, staff) => result && staff.isValid(),
        check
      );
    }

    scrollToRequired();
    return check;
  }

  addComplianceMembers() {
    this.complianceMembers.push(new ComplianceMember());
  }

  removeComplianceMembers(inspector) {
    if (this.complianceMembers.length > 1) {
      this.complianceMembers.pop();
    } else {
      toast.error(
        IntlService.formatMessage("Minimum 1 compliance Members required")
      );
    }
  }

  get allIdsOfSelectedMedicines() {
    return this.medicine.map((item) => item._id);
  }

  get preSelectesMedicines() {
    return this.allMedicinesOptions.filter(({ preSelected }) => preSelected);
  }

  get allMedicines() {
    if (this.allMedicinesOptions?.length) {
      return this.allMedicinesOptions?.filter(
        ({ _id }) => !this.allIdsOfSelectedMedicines.includes(_id)
      );
    }

    return [];
  }

  checkValidationForStep() {
    let check = true;

    const stepName = this.getCurrentStep();

    if (stepName === "identification") {
      check =
        !!this.region &&
        !!this.site &&
        !!this.street_name &&
        !!this.city &&
        !!this.district_name &&
        !!this.longitude &&
        !!this.latitude;
    } else if (stepName === "general_medicine") {
      this.medicine.forEach((med) => (check = check && med.isValid()));
    } else if (stepName === "respiratory_medicine") {
      this.medicineRes.forEach((med) => (check = check && med.isValid()));
    } else {
      check =
        check &&
        this.compDelegatedPerson &&
        this.compIdNumber &&
        this.compIssueDate &&
        this.compIssuePlace &&
        this.composition &&
        this.compSignature?.signature?.length;

      check = this?.complianceMembers?.reduce(
        (result, staff) => result && staff.isValid(),
        check
      );
    }
    scrollToRequired();

    return check;
  }

  //validation free all steps except
  nextStep(msg) {
    this.test = !this.test;

    if (this.checkValidationForStep()) {
      this.stepper.next();
      this.test = !this.test;
      this.isValidation = false;
    } else {
      this.isValidation = true;
      toast.error(IntlService.m("Please fill all the required fields!"));
    }
  }

  review = () => {
    let check = this.isValid();

    if (!check) {
      this.isValidation = true;
      toast.error(IntlService.m("Please fill all the required fields!"));
      return;
    }

    this.setIsReview(true);
    this.isValidation = false;
    this.test = !this.test;
  };

  onStepPress = ({ stepNumber }) => {
    this.stepper.to(stepNumber);
    this.test = !this.test;
  };
  modifiedText = (text) => {
    switch (text) {
      case "emergency_department_men_women":
        return "Emergency Department Men & Women";
      case "other_doctors_clinics":
        return "Other Doctor's Clinics";
      case "day_surgery_center_yes":
        return "Day Surgery Center ( Yes";

      default:
        return startCase(text);
    }
  };

  // modifiedText = modifiedText;

  // isIdentificationStep = () => {
  //   return this.stepper?._currentIndex === this.identificationStep;
  // };
}
