import Language from "./Language";
import { useNavigate } from "react-router-dom";

import { SC } from "../../../../views/wasfaty/Api/serverCall";
import UserDropdown from "../../../components/Profile/ProfileDropDown";
import ExpertCallDropDown from "../../../components/ExperCall/ExpertCallDropDown";
import QuickAddDropdown from "./QuickAddDropdown";
import AuthUser from "../../../../views/wasfaty/services/AuthService";
const NavbarUser = ({ windowWidth }) => {
  const navigate = useNavigate();

  const logOut = (e) => {
    e.preventDefault();
    navigate("/login");

    SC.getCall({ url: "logout" }).then((response) => {});
    localStorage.clear();
  };

  return (
    <>
      <ul
        className="nav  d-flex align-items-center ms-auto flex-nowrap"
        style={{ marginTop: -5 }}
      >
      {/*   {!AuthUser.isFacility && (
          <li>
            <ExpertCallDropDown navigate={navigate} />
          </li>
        )} */}

        <li className="mx-1 ">
          <Language />
        </li>
        <li>v{process.env.REACT_APP_VERSION}</li>
        <>
          {!AuthUser.isFacility && (
            <li className="mx-1">
              <QuickAddDropdown windowWidth={windowWidth} />
            </li>
          )}

          <li>
            <UserDropdown logOut={logOut} />
          </li>
        </>
      </ul>
    </>
  );
};
export default NavbarUser;
