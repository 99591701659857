import { Singleton, Resources } from "../Resource";
import { SC } from "../Api/serverCall";
import get from "lodash/get";

class Service extends Resources {
  route = "region";
  byMethod = "get-form-regions";
  byAllRegions = "get-all-regions";
  regions = [];

  constructor() {
    super(arguments);
  }

  getByMethod = async (method) => {
    if (this.regions.length) {
      return this.regions;
    }

    try {
      let res = await SC.getCall({
        url: this.byMethod,
        params: { method },
      });
      let _regions = get(res, "data.data");
      if (_regions?.length) {
        this.regions = _regions.map((item) => ({
          ...item,
          label: item.name,
          value: item._id,
        }));

        return this.regions;
      }

      return [];
    } catch (e) {
      return [];
    }
  };

  getAllRegions() {
    return SC.getCall({
      url: this.byAllRegions,
    });
  }

  getHeaders() {
    return [
      { title: "Name", dataIndex: "name" },
      { title: "Created at", dataIndex: "created_at" },
    ];
  }

  mapExportData(data) {
    return {
      headers: this.getHeaders(),
      data,
    };
  }
}

const RegionService = new Service();

export default RegionService;
