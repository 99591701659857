// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import users from "@src/views/apps/user/store";

const rootReducer = {
  users,
  navbar,
  layout,
};

export default rootReducer;
