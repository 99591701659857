import moment from "moment";
import React, { useState } from "react";
import { selectThemeColors } from "@utils";
import toast from "react-hot-toast";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Spinner,
} from "reactstrap";
import ButtonGreen from "../../../../components/buttons/ButtonGreen";
import { UserSearchModal } from "../../../../wasfaty/Models/UserSearchModal";
import Select from "react-select";
import {
  IntlService,
  ModuleService,
  TaskService,
} from "../../../../wasfaty/services";
import { observer } from "mobx-react/dist";
import { useMemo } from "react";
import ButtonGreenOutline from "../../../../components/buttons/ButtonGreenOutline";
import TaskModal, { TaskUser } from "../../../../wasfaty/Models/TaskModal";
/**
 * @param {Object} props
 * @param {TaskModal} props.task
 */
function ReAssignTaskModal({ modal, toggle, task, close }) {
  const [userModal] = useState(() => new UserSearchModal());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedUser, setSelectedUser] = useState(() => {
    return task.user;
  });

  const moduleId = useMemo(
    () => ModuleService.getModuleIdBySlug(task?.form_name),
    [task?.form_name]
  );

  const isUserHasAccess = selectedUser?.module_ids?.includes?.(moduleId);

  const isOtherUser = useMemo(() => {
    return selectedUser._id != task.user._id || task.is_cancelled;
  }, [task.user, selectedUser._id]);

  const reassignTask = () => {
    if (isOtherUser) {
      setIsSubmitting(true);
      TaskService.reAssign(task._id, selectedUser.value).then((res) => {
        let _task = res.data.data;
        if (_task) {
          task.init(_task);
          toast.success("Successfully Re-Assigned!");
        }
        close();
      });
    }
  };

  return (
    <Modal isOpen={modal} toggle={toggle} unmountOnClose={true}>
      <ModalHeader toggle={toggle}>Inspectors</ModalHeader>
      <ModalBody>
        <Col>
          
          <Select
            placeholder={IntlService.m(
              userModal.isLoading ? "Loading..." : "Select"
            )}
            name="inspector"
            onChange={(e) => {
              setSelectedUser(new TaskUser(e));
            }}
            value={selectedUser}
            theme={selectThemeColors}
            className="react-select mt-2"
            classNamePrefix="select"
            options={userModal.users}
            isLoading={userModal.isLoading}
            onInputChange={userModal.onSearch}
          />
        </Col>
      </ModalBody>

      <ModalFooter>
        {isSubmitting ? (
          <Spinner color="primary" />
        ) : (
          <ButtonGreen
            onClick={reassignTask}
            title="Submit"
            disabled={!isOtherUser}
          />
        )}
      </ModalFooter>
    </Modal>
  );
}

export default observer(ReAssignTaskModal);
