import { Singleton, Resources } from "../Resource";
import get from "lodash/get";

class Service extends Resources {
  disableDefault = true;
  route = "medicationList";

  constructor() {
    super(arguments);
  }
  getHeaders() {
    return [
      { title: "Region", dataIndex: "region" },
      { title: "Site", dataIndex: "site" },
      { title: "User", dataIndex: "user" },
      // { title: "Medication", dataIndex: "medication" },
      // { title: "High In Demand", dataIndex: "high_in_demand" },
      // { title: "Available In Pharmacy", dataIndex: "available_in_pharmacy" },
      // { title: "Low Stock In Shelf", dataIndex: "low_stock_in_shelf" },
      { title: "Created at", dataIndex: "created_at" },
    ];
  }

  mapExportData(data) {
    data = data.map((item) => {
      item.region = get(item, "region.name", "");
      item.site = (item.site?._id ? item.site : item.obligation_site) || {};

      let name_en = item.site?.name;
      let name_ar = item.site?.name_ar;
      let name = name_ar || name_en || "N/A";
      if (name_en && name_ar) {
        name = name_en + " - " + name_ar;
      }

      item.license = item?.site?.license || item?.site?.licenseNumber || "N/A";
      item.site = name;

      item.user = get(item, "user.name", "");
      item.medication = get(item, "medication.name", "");

      return item;
    });
    return {
      headers: this.getHeaders(),
      data,
    };
  }
}

export default Singleton(Service);

let a = {
  _id: "62823189a4b1d8089e4c9ce3",
  medicine_id: "627a4603e587270e656ff579",
  available_in_pharmacy: false,
  low_stock_in_shelf: false,
  high_in_demand: false,
  medication_submission_id: "62823189a4b1d8089e4c9ce1",
  updated_at: "2022-05-16T11:12:09.282000Z",
  created_at: "2022-05-16T11:12:09.282000Z",
  medication: {
    _id: "627a4603e587270e656ff579",
    name: "test3",
    genericCode: "1111111",
    required: true,
    updated_at: "2022-05-10T11:01:23.035000Z",
    created_at: "2022-05-10T11:01:23.035000Z",
  },
  submission: {
    _id: "62823189a4b1d8089e4c9ce1",
    region_id: "6280d6821c953f56f8120a38",
    site_id: "6280d68d88c51f04446720a4",
    user_id: "628207a1ad279431ac506432",
    updated_at: "2022-05-16T11:12:09.280000Z",
    created_at: "2022-05-16T11:12:09.280000Z",
    region: {
      _id: "6280d6821c953f56f8120a38",
      name: "Punjab",
      phcRegion: false,
      updated_at: "2022-05-15T10:31:30.637000Z",
      created_at: "2022-05-15T10:31:30.637000Z",
    },
    site: {
      _id: "6280d68d88c51f04446720a4",
      name: "Lahore",
      region_id: "6280d6821c953f56f8120a38",
      type: true,
      phcSite: true,
      siteType: "Lab",
      updated_at: "2022-05-15T10:31:41.905000Z",
      created_at: "2022-05-15T10:31:41.905000Z",
    },
    user: {
      _id: "628207a1ad279431ac506432",
      name: "Danial",
      email: "danial.ghazali@ascend.com.sa",
      type: "admin",
      phone: "966562860255",
      updated_at: "2022-05-16T10:25:52.293000Z",
      created_at: "2022-05-16T08:13:21.310000Z",
      refreshToken_at: "2022-05-16T10:25:52.292000Z",
    },
  },
};
