import { makeAutoObservable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import FileUploadModal from "./FileUploadModel";

class MedicineModel {
  _id = null;
  medicine = null;
  name = "";
  medicine_id = null;
  is_the_medicine_avilable = null; //Is the medicine Available ? //moreop
  quantity_available = null; //Quantity Available?
  consumption_days_quantity = null; //? expected consumption days based on the available quantity
  vendor_to_provide_this_medicine = null; //Did you ask the vendor to provide this medicine  //moreop
  complian_provide_this_medicine = null; //Did you complain to SFDA that vendor Does not provide this medicine //moreop
  quantity_match_requirement = null; //Is the quantity match the requirement?
  the_report_number = null; //Is the quantity match the requirement?
  isOpen = true;
  reporting_Date = new Date();

  preSelected = false;

  exportObj = [
    {
      title: "Medicine",
      attribute: "medicine.name",
    },
    {
      title: "Is the medicine Available?",
      attribute: "is_the_medicine_avilable",
      moreOp: true,
    },
    {
      title: "Quantity Available?",
      attribute: "quantity_available",
    },
    {
      title: "Is the quantity match the requirement?",
      attribute: "quantity_match_requirement",
    },
    {
      title: "Did you ask the vendor to provide this medicine",
      attribute: "vendor_to_provide_this_medicine",
      moreOp: true,
    },
    {
      title: "Expected consumption days based on the available quantity?",
      attribute: "consumption_days_quantity",
      moreOp: false,
    },
    {
      title:
        "Did you complain to SFDA that vendor Does not provide this medicine",
      attribute: "complian_provide_this_medicine",
      moreOp: true,
    },
    {
      title: "Reporting Date",
      attribute: "reporting_Date",
    },
    {
      title: "The Reporting Number",
      attribute: "the_report_number",
    },
  ];

  constructor(props, isEdit) {
    this.__id = uuidv4();

    this.moreOptions = [
      "is_the_medicine_avilable",
      "vendor_to_provide_this_medicine",
      "complian_provide_this_medicine",
    ];

    this.moreOptions.forEach((element) => {
      this[element + "_other"] = null;
      this[element + "_file"] = new FileUploadModal();
    });
    if (props) {
      this._id = props._id;
      this.preSelected = props.preSelected;
      this.medicine = { ...props, value: props._id, label: props.name };
      this.name = props.name
    }

    if (isEdit) {
      Object.entries(props).forEach(([name, value]) => {
        if (name.includes("_file")) {
          this[name] = new FileUploadModal(value);
          this[name].setImages(value);
        } else {
          this.setAttribute(name, this.isDropDown(name, value));
        }
      });
    }

    makeAutoObservable(this);
  }

  get dropDownKeys() {
    return [];
  }

  isDropDown(name, value) {
    if (this.dropDownKeys.includes(name)) {
      value = { label: value, value };
    }

    return value;
  }

  isValid(props = {}) {
    let check = !!this.medicine && !!this.is_the_medicine_avilable;
    if (this.is_the_medicine_avilable == "Yes") {
      // console.log("yes", this.is_the_medicine_avilable_file.images.length > 0);
      check =
        check &&
        // this.is_the_medicine_avilable_file.images.length > 0 &&

        !!this.quantity_available
        //  &&this.consumption_days_quantity;
      // if (props.audit != "medicine-availibility") {
      //   check = check && !!this.quantity_match_requirement;
      // }
    }

    // if (props.audit === "medicine-availibility") {
    //   check = check && !!this.the_report_number;
    // }

    if (this.is_the_medicine_avilable == "No") {
      check =
        check &&
        !!this.vendor_to_provide_this_medicine &&
        // this.vendor_to_provide_this_medicine_file.images.length > 0 &&
        // !!this.vendor_to_provide_this_medicine_other &&
        !!this.complian_provide_this_medicine;
      // this.complian_provide_this_medicine_file.images.length > 0 &&
      // !!this.complian_provide_this_medicine_other;
    }
    return check;
  }

  getPayload(props = {}) {
    let payload = {};
    payload._id = this._id;
    payload.medicine = this.medicine;
    payload.is_the_medicine_avilable = this.is_the_medicine_avilable; //Is the medicine Available ? //moreop
    payload.name = this.name

    if (this.is_the_medicine_avilable == "Yes") {
      payload.is_the_medicine_avilable_file =
        this.is_the_medicine_avilable_file.images; //Is the medicine Available ? //moreop
      payload.is_the_medicine_avilable_other =
        this.is_the_medicine_avilable_other; //Is the medicine Available ? //moreop
      payload.quantity_available = this.quantity_available; //Quantity Available?

      // payload.consumption_days_quantity = this.consumption_days_quantity;
    } else if (this.is_the_medicine_avilable == "No") {
      if (this.complian_provide_this_medicine == "Yes")
        try {
          payload.reporting_Date = new Date(this.reporting_Date).toISOString();
        } catch (error) {}
      payload.vendor_to_provide_this_medicine =
        this.vendor_to_provide_this_medicine; //Did you ask the vendor to provide this medicine  //moreop
      payload.vendor_to_provide_this_medicine_file =
        this.vendor_to_provide_this_medicine_file.images;
      payload.vendor_to_provide_this_medicine_other =
        this.vendor_to_provide_this_medicine_other;
      payload.complian_provide_this_medicine =
        this.complian_provide_this_medicine; //Did you complain to SFDA that vendor Does not provide this medicine //moreop
      payload.complian_provide_this_medicine_file =
        this.complian_provide_this_medicine_file.images;
      payload.complian_provide_this_medicine_other =
        this.complian_provide_this_medicine_other;

      payload.the_report_number = this.the_report_number;
    }

    payload.preSelected = this.preSelected;
    return payload;
  }

  setAttribute(name, value) {
    if (name == "medicine") {
      this._id = value?._id;
    }
    if (name == "vendor_to_provide_this_medicine" && value == "No") {
      this.complian_provide_this_medicine = "No";
    }

    this[name] = value;
  }
}

export default MedicineModel;
