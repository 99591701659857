import React from "react";

import { Row, Col, Card, CardBody } from "reactstrap";
import SignatureCanvas from "react-signature-canvas";
import { observer } from "mobx-react-lite";
import TextView from "../../components/TextView";
import { IntlService } from "../../wasfaty/services";
import {
  arabicName,
  city,
  facilityType,
  liscenceNumb,
  longAndLat,
} from "../assets/svg";

const RenderCol = ({ name, value, icon, col }) => {
  return (
    <Col
      md={col || "6"}
      className="d-flex justify-content-start gap-2 tableCol"
    >
      <figure>{icon}</figure>

      <div className="d-flex justify-content-start flex-column ">
        <p className="title">{IntlService.m(name)}</p>
        <p className="value">{value}</p>
      </div>
    </Col>
  );
};

function InstituteModal({ item, dataTorender }) {
  return (
    <div className="table_container">
      <Row className="mx-auto">
        <RenderCol
          name="Arabic Name"
          value={item["name_ar"]}
          icon={arabicName}
        />
        <RenderCol
          name="Licence number"
          value={item["licenseNumber"]}
          icon={liscenceNumb}
        />
        <RenderCol
          name="Facility type"
          value={item["facilityType"]}
          icon={facilityType}
        />
        <RenderCol name="City" value={item["city"]} icon={city} />
        <RenderCol
          name="Latitude and longitude"
          value={`${item["latitude"]}, ${item["longitude"]}`}
          icon={longAndLat}
          col="12"
        />
      </Row>
    </div>
  );
}

export default InstituteModal;
