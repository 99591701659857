export const mail = (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.25 0.75H14.75C14.9489 0.75 15.1397 0.829018 15.2803 0.96967C15.421 1.11032 15.5 1.30109 15.5 1.5V12.5C15.5 12.6989 15.421 12.8897 15.2803 13.0303C15.1397 13.171 14.9489 13.25 14.75 13.25H1.25C1.05109 13.25 0.860322 13.171 0.71967 13.0303C0.579018 12.8897 0.5 12.6989 0.5 12.5V1.5C0.5 1.30109 0.579018 1.11032 0.71967 0.96967C0.860322 0.829018 1.05109 0.75 1.25 0.75ZM14 3.9285L8.054 9.2535L2 3.912V11.75H14V3.9285ZM2.38325 2.25L8.04575 7.2465L13.6265 2.25H2.38325Z"
      fill="#868C98"
    />
  </svg>
);

export const eye = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99999 3.25C14.044 3.25 17.4085 6.16 18.1142 10C17.4092 13.84 14.044 16.75 9.99999 16.75C5.95599 16.75 2.59149 13.84 1.88574 10C2.59074 6.16 5.95599 3.25 9.99999 3.25ZM9.99999 15.25C11.5296 15.2497 13.0138 14.7301 14.2096 13.7764C15.4055 12.8226 16.2422 11.4912 16.5827 10C16.2409 8.50998 15.4037 7.18 14.208 6.22752C13.0122 5.27504 11.5287 4.7564 9.99999 4.7564C8.47126 4.7564 6.98776 5.27504 5.79202 6.22752C4.59629 7.18 3.75907 8.50998 3.41724 10C3.75781 11.4912 4.5945 12.8226 5.79035 13.7764C6.9862 14.7301 8.47039 15.2497 9.99999 15.25ZM9.99999 13.375C9.10489 13.375 8.24644 13.0194 7.61351 12.3865C6.98057 11.7535 6.62499 10.8951 6.62499 10C6.62499 9.10489 6.98057 8.24645 7.61351 7.61351C8.24644 6.98058 9.10489 6.625 9.99999 6.625C10.8951 6.625 11.7535 6.98058 12.3865 7.61351C13.0194 8.24645 13.375 9.10489 13.375 10C13.375 10.8951 13.0194 11.7535 12.3865 12.3865C11.7535 13.0194 10.8951 13.375 9.99999 13.375ZM9.99999 11.875C10.4973 11.875 10.9742 11.6775 11.3258 11.3258C11.6774 10.9742 11.875 10.4973 11.875 10C11.875 9.50272 11.6774 9.02581 11.3258 8.67417C10.9742 8.32254 10.4973 8.125 9.99999 8.125C9.50271 8.125 9.0258 8.32254 8.67417 8.67417C8.32254 9.02581 8.12499 9.50272 8.12499 10C8.12499 10.4973 8.32254 10.9742 8.67417 11.3258C9.0258 11.6775 9.50271 11.875 9.99999 11.875Z"
      fill="#868C98"
    />
  </svg>
);

export const headPhones = (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 4.79132C8.4087 4.79132 6.88258 5.42346 5.75736 6.54868C4.63214 7.6739 4 9.20002 4 10.7913H6.25C6.64782 10.7913 7.02936 10.9494 7.31066 11.2307C7.59196 11.512 7.75 11.8935 7.75 12.2913V16.0413C7.75 16.4391 7.59196 16.8207 7.31066 17.102C7.02936 17.3833 6.64782 17.5413 6.25 17.5413H4C3.60218 17.5413 3.22064 17.3833 2.93934 17.102C2.65804 16.8207 2.5 16.4391 2.5 16.0413V10.7913C2.5 6.64907 5.85775 3.29132 10 3.29132C14.1423 3.29132 17.5 6.64907 17.5 10.7913V16.0413C17.5 16.4391 17.342 16.8207 17.0607 17.102C16.7794 17.3833 16.3978 17.5413 16 17.5413H13.75C13.3522 17.5413 12.9706 17.3833 12.6893 17.102C12.408 16.8207 12.25 16.4391 12.25 16.0413V12.2913C12.25 11.8935 12.408 11.512 12.6893 11.2307C12.9706 10.9494 13.3522 10.7913 13.75 10.7913H16C16 9.20002 15.3679 7.6739 14.2426 6.54868C13.1174 5.42346 11.5913 4.79132 10 4.79132ZM4 12.2913V16.0413H6.25V12.2913H4ZM13.75 12.2913V16.0413H16V12.2913H13.75Z"
      fill="#375DFB"
    />
  </svg>
);

export const language = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 17.5C5.85775 17.5 2.5 14.1422 2.5 10C2.5 5.85775 5.85775 2.5 10 2.5C14.1422 2.5 17.5 5.85775 17.5 10C17.5 14.1422 14.1422 17.5 10 17.5ZM8.2825 15.7502C7.54256 14.1807 7.1139 12.4827 7.02025 10.75H4.0465C4.19244 11.9042 4.67044 12.9911 5.42243 13.8788C6.17441 14.7664 7.16801 15.4166 8.2825 15.7502ZM8.5225 10.75C8.63575 12.5792 9.1585 14.2975 10 15.814C10.8642 14.2574 11.3691 12.5271 11.4775 10.75H8.5225ZM15.9535 10.75H12.9797C12.8861 12.4827 12.4574 14.1807 11.7175 15.7502C12.832 15.4166 13.8256 14.7664 14.5776 13.8788C15.3296 12.9911 15.8076 11.9042 15.9535 10.75ZM4.0465 9.25H7.02025C7.1139 7.51734 7.54256 5.81926 8.2825 4.24975C7.16801 4.58341 6.17441 5.23356 5.42243 6.12122C4.67044 7.00888 4.19244 8.09583 4.0465 9.25ZM8.52325 9.25H11.4767C11.3686 7.47295 10.864 5.74265 10 4.186C9.13576 5.74259 8.63092 7.47289 8.5225 9.25H8.52325ZM11.7175 4.24975C12.4574 5.81926 12.8861 7.51734 12.9797 9.25H15.9535C15.8076 8.09583 15.3296 7.00888 14.5776 6.12122C13.8256 5.23356 12.832 4.58341 11.7175 4.24975Z"
      fill="#868C98"
    />
  </svg>
);

export const lock = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 7V6.25C5.5 5.05653 5.97411 3.91193 6.81802 3.06802C7.66193 2.22411 8.80653 1.75 10 1.75C11.1935 1.75 12.3381 2.22411 13.182 3.06802C14.0259 3.91193 14.5 5.05653 14.5 6.25V7H16C16.1989 7 16.3897 7.07902 16.5303 7.21967C16.671 7.36032 16.75 7.55109 16.75 7.75V16.75C16.75 16.9489 16.671 17.1397 16.5303 17.2803C16.3897 17.421 16.1989 17.5 16 17.5H4C3.80109 17.5 3.61032 17.421 3.46967 17.2803C3.32902 17.1397 3.25 16.9489 3.25 16.75V7.75C3.25 7.55109 3.32902 7.36032 3.46967 7.21967C3.61032 7.07902 3.80109 7 4 7H5.5ZM15.25 8.5H4.75V16H15.25V8.5ZM9.25 12.799C8.96404 12.6339 8.74054 12.3791 8.61418 12.074C8.48782 11.7689 8.46565 11.4307 8.55111 11.1117C8.63657 10.7928 8.82489 10.5109 9.08686 10.3099C9.34882 10.1089 9.6698 9.99996 10 9.99996C10.3302 9.99996 10.6512 10.1089 10.9131 10.3099C11.1751 10.5109 11.3634 10.7928 11.4489 11.1117C11.5344 11.4307 11.5122 11.7689 11.3858 12.074C11.2595 12.3791 11.036 12.6339 10.75 12.799V14.5H9.25V12.799ZM7 7H13V6.25C13 5.45435 12.6839 4.69129 12.1213 4.12868C11.5587 3.56607 10.7956 3.25 10 3.25C9.20435 3.25 8.44129 3.56607 7.87868 4.12868C7.31607 4.69129 7 5.45435 7 6.25V7Z"
      fill="#868C98"
    />
  </svg>
);

export const arrowRight = (
  <svg
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.79575 4.99926L0.083252 1.28676L1.14375 0.226257L5.91675 4.99926L1.14375 9.77226L0.083252 8.71176L3.79575 4.99926Z"
      fill="#375DFB"
    />
  </svg>
);

export const info = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 14C4.6862 14 2 11.3138 2 8C2 4.6862 4.6862 2 8 2C11.3138 2 14 4.6862 14 8C14 11.3138 11.3138 14 8 14ZM7.4 9.8V11H8.6V9.8H7.4ZM7.4 5V8.6H8.6V5H7.4Z"
      fill="#868C98"
    />
  </svg>
);

export const arabicName = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#F6F8FA" />
    <path
      d="M21.5 26.75L20 28.25L18.5 26.75H14.7462C14.3494 26.75 13.9688 26.5924 13.6882 26.3118C13.4076 26.0312 13.25 25.6506 13.25 25.2537V14.7462C13.25 13.9197 13.9197 13.25 14.7462 13.25H25.2537C26.0802 13.25 26.75 13.9197 26.75 14.7462V25.2537C26.75 25.6506 26.5924 26.0312 26.3118 26.3118C26.0312 26.5924 25.6506 26.75 25.2537 26.75H21.5ZM25.25 25.25V14.75H14.75V25.25H19.121L20 26.129L20.879 25.25H25.25ZM16.979 24.635C16.5177 24.4352 16.0778 24.1895 15.6658 23.9015C16.1414 23.1643 16.7945 22.5581 17.565 22.1386C18.3356 21.719 19.1991 21.4995 20.0765 21.5C21.8765 21.5 23.4642 22.4053 24.41 23.7853C24.0059 24.0842 23.5727 24.3418 23.117 24.554C22.7697 24.0725 22.3128 23.6805 21.7842 23.4103C21.2555 23.1401 20.6702 22.9995 20.0765 23C18.7903 23 17.654 23.648 16.979 24.635ZM20 20.75C19.6553 20.75 19.3139 20.6821 18.9955 20.5502C18.677 20.4183 18.3876 20.2249 18.1438 19.9812C17.9001 19.7374 17.7067 19.448 17.5748 19.1295C17.4429 18.8111 17.375 18.4697 17.375 18.125C17.375 17.7803 17.4429 17.4389 17.5748 17.1205C17.7067 16.802 17.9001 16.5126 18.1438 16.2688C18.3876 16.0251 18.677 15.8317 18.9955 15.6998C19.3139 15.5679 19.6553 15.5 20 15.5C20.6962 15.5 21.3639 15.7766 21.8562 16.2688C22.3484 16.7611 22.625 17.4288 22.625 18.125C22.625 18.8212 22.3484 19.4889 21.8562 19.9812C21.3639 20.4734 20.6962 20.75 20 20.75ZM20 19.25C20.2984 19.25 20.5845 19.1315 20.7955 18.9205C21.0065 18.7095 21.125 18.4234 21.125 18.125C21.125 17.8266 21.0065 17.5405 20.7955 17.3295C20.5845 17.1185 20.2984 17 20 17C19.7016 17 19.4155 17.1185 19.2045 17.3295C18.9935 17.5405 18.875 17.8266 18.875 18.125C18.875 18.4234 18.9935 18.7095 19.2045 18.9205C19.4155 19.1315 19.7016 19.25 20 19.25Z"
      fill="#525866"
    />
  </svg>
);

export const liscenceNumb = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#F6F8FA" />
    <path
      d="M26 20C25.9999 18.6627 25.5529 17.3637 24.7302 16.3094C23.9074 15.2551 22.7561 14.5059 21.4588 14.1807C20.1616 13.8555 18.793 13.9731 17.5702 14.5147C16.3474 15.0562 15.3406 15.9908 14.7097 17.1699C14.0788 18.3491 13.8599 19.7052 14.0877 21.023C14.3156 22.3408 14.9772 23.5447 15.9675 24.4435C16.9577 25.3423 18.2199 25.8845 19.5535 25.984C20.8872 26.0835 22.2158 25.7346 23.3285 24.9928L24.161 26.2407C22.9297 27.064 21.4812 27.5024 20 27.5C15.8578 27.5 12.5 24.1423 12.5 20C12.5 15.8578 15.8578 12.5 20 12.5C24.1423 12.5 27.5 15.8578 27.5 20V21.125C27.5001 21.6866 27.3201 22.2334 26.9864 22.685C26.6528 23.1367 26.183 23.4695 25.6463 23.6345C25.1095 23.7995 24.5339 23.788 24.0042 23.6017C23.4744 23.4154 23.0184 23.0642 22.703 22.5995C22.2024 23.12 21.5622 23.4849 20.8593 23.6503C20.1564 23.8158 19.4206 23.7748 18.7404 23.5322C18.0602 23.2896 17.4646 22.8558 17.025 22.2829C16.5854 21.7099 16.3206 21.0223 16.2623 20.3025C16.2041 19.5827 16.3549 18.8614 16.6967 18.2253C17.0385 17.5891 17.5567 17.0652 18.189 16.7164C18.8214 16.3677 19.541 16.2089 20.2613 16.2592C20.9817 16.3095 21.6723 16.5667 22.25 17H23.75V21.125C23.75 21.4234 23.8685 21.7095 24.0795 21.9205C24.2905 22.1315 24.5766 22.25 24.875 22.25C25.1734 22.25 25.4595 22.1315 25.6705 21.9205C25.8815 21.7095 26 21.4234 26 21.125V20ZM20 17.75C19.4033 17.75 18.831 17.9871 18.409 18.409C17.9871 18.831 17.75 19.4033 17.75 20C17.75 20.5967 17.9871 21.169 18.409 21.591C18.831 22.0129 19.4033 22.25 20 22.25C20.5967 22.25 21.169 22.0129 21.591 21.591C22.0129 21.169 22.25 20.5967 22.25 20C22.25 19.4033 22.0129 18.831 21.591 18.409C21.169 17.9871 20.5967 17.75 20 17.75Z"
      fill="#525866"
    />
  </svg>
);

export const facilityType = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#F6F8FA" />
    <path
      d="M13.25 25.25V15.275C13.25 15.1211 13.2973 14.9708 13.3856 14.8447C13.4739 14.7186 13.5988 14.6227 13.7435 14.57L20.9967 11.933C21.0534 11.9124 21.1142 11.9057 21.174 11.9136C21.2338 11.9215 21.2908 11.9437 21.3401 11.9783C21.3895 12.0128 21.4298 12.0588 21.4577 12.1123C21.4855 12.1658 21.5 12.2252 21.5 12.2855V16.0003L26.237 17.579C26.3864 17.6288 26.5164 17.7243 26.6084 17.852C26.7005 17.9798 26.75 18.1333 26.75 18.2908V25.25H28.25V26.75H11.75V25.25H13.25ZM14.75 25.25H20V13.8913L14.75 15.8008V25.25ZM25.25 25.25V18.8315L21.5 17.5813V25.25H25.25Z"
      fill="#525866"
    />
  </svg>
);

export const city = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#F6F8FA" />
    <path
      d="M19.25 24.4535C17.737 24.261 16.3541 23.4996 15.3824 22.3241C14.4106 21.1486 13.923 19.6472 14.0185 18.125C14.114 16.6028 14.7855 15.1741 15.8966 14.1293C17.0076 13.0845 18.4748 12.5019 20 12.5C21.527 12.4989 22.997 13.08 24.1105 14.125C25.224 15.17 25.8971 16.6002 25.9927 18.1243C26.0883 19.6483 25.5992 21.1515 24.6251 22.3274C23.651 23.5034 22.2652 24.2638 20.75 24.4535V26.009C23.7095 26.078 26 26.5513 26 27.125C26 27.746 23.3135 28.25 20 28.25C16.6865 28.25 14 27.746 14 27.125C14 26.5513 16.2905 26.078 19.25 26.009V24.4535ZM20 23C21.1935 23 22.3381 22.5259 23.182 21.682C24.0259 20.8381 24.5 19.6935 24.5 18.5C24.5 17.3065 24.0259 16.1619 23.182 15.318C22.3381 14.4741 21.1935 14 20 14C18.8065 14 17.6619 14.4741 16.818 15.318C15.9741 16.1619 15.5 17.3065 15.5 18.5C15.5 19.6935 15.9741 20.8381 16.818 21.682C17.6619 22.5259 18.8065 23 20 23ZM20 20C19.6022 20 19.2206 19.842 18.9393 19.5607C18.658 19.2794 18.5 18.8978 18.5 18.5C18.5 18.1022 18.658 17.7206 18.9393 17.4393C19.2206 17.158 19.6022 17 20 17C20.3978 17 20.7794 17.158 21.0607 17.4393C21.342 17.7206 21.5 18.1022 21.5 18.5C21.5 18.8978 21.342 19.2794 21.0607 19.5607C20.7794 19.842 20.3978 20 20 20Z"
      fill="#525866"
    />
  </svg>
);

export const longAndLat = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#F6F8FA" />
    <path
      d="M12.5 14.75L17.75 12.5L22.25 14.75L26.9772 12.7243C27.0343 12.6998 27.0966 12.6899 27.1584 12.6954C27.2202 12.7009 27.2797 12.7217 27.3316 12.7559C27.3834 12.7901 27.4259 12.8367 27.4553 12.8913C27.4847 12.946 27.5001 13.0072 27.5 13.0693V25.25L22.25 27.5L17.75 25.25L13.0228 27.2758C12.9657 27.3002 12.9034 27.3101 12.8416 27.3046C12.7798 27.2991 12.7203 27.2783 12.6684 27.2441C12.6166 27.2099 12.5741 27.1633 12.5447 27.1087C12.5153 27.054 12.4999 26.9928 12.5 26.9307V14.75ZM23 25.5463L26 24.2607V14.7747L23 16.0603V25.5463ZM21.5 25.448V16.052L18.5 14.552V23.948L21.5 25.448ZM17 23.9398V14.4538L14 15.7392V25.2252L17 23.9398Z"
      fill="#525866"
    />
  </svg>
);

export const saudiFlag = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2474_1215)">
      <path
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill="#6DA544"
      />
      <path
        d="M5.65234 11.9565C5.65234 12.6769 6.23633 13.2609 6.95668 13.2609H10.8697C10.8697 13.8612 11.3564 14.3478 11.9567 14.3478H13.261C13.8613 14.3478 14.348 13.8612 14.348 13.2609V11.9565H5.65234Z"
        fill="#F0F0F0"
      />
      <path
        d="M14.4565 5.65222V8.6957C14.4565 9.17519 14.0664 9.56527 13.5869 9.56527V10.8696C14.7856 10.8696 15.7608 9.89437 15.7608 8.6957V5.65222H14.4565Z"
        fill="#F0F0F0"
      />
      <path
        d="M5.10883 8.6957C5.10883 9.17519 4.71875 9.56527 4.23926 9.56527V10.8696C5.43797 10.8696 6.41316 9.89437 6.41316 8.6957V5.65222H5.10883V8.6957Z"
        fill="#F0F0F0"
      />
      <path d="M12.5 5.65222H13.8043V8.6957H12.5V5.65222Z" fill="#F0F0F0" />
      <path
        d="M10.5437 7.3913C10.5437 7.51118 10.4461 7.60868 10.3263 7.60868C10.2064 7.60868 10.1089 7.51114 10.1089 7.3913V5.65216H8.80457V7.3913C8.80457 7.51118 8.70703 7.60868 8.58719 7.60868C8.46734 7.60868 8.3698 7.51114 8.3698 7.3913V5.65216H7.06543V7.3913C7.06543 8.2304 7.74809 8.91306 8.58719 8.91306C8.91035 8.91306 9.21004 8.8115 9.45676 8.639C9.70344 8.81146 10.0032 8.91306 10.3263 8.91306C10.3914 8.91306 10.4554 8.90849 10.5183 8.90048C10.4259 9.2813 10.0829 9.56521 9.67414 9.56521V10.8695C10.8729 10.8695 11.848 9.89431 11.848 8.69564V7.3913V5.65216H10.5437V7.3913H10.5437Z"
        fill="#F0F0F0"
      />
      <path
        d="M7.06543 9.56525H9.02195V10.8696H7.06543V9.56525Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_2474_1215">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const valid = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 12C2.6862 12 0 9.3138 0 6C0 2.6862 2.6862 0 6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12ZM5.4018 8.4L9.6438 4.1574L8.7954 3.309L5.4018 6.7032L3.7044 5.0058L2.856 5.8542L5.4018 8.4Z"
      fill="#38C793"
    />
  </svg>
);

export const inVlaidicon = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 12C2.6862 12 0 9.3138 0 6C0 2.6862 2.6862 0 6 0C9.3138 0 12 2.6862 12 6C12 9.3138 9.3138 12 6 12ZM6 5.1516L4.3032 3.4542L3.4542 4.3032L5.1516 6L3.4542 7.6968L4.3032 8.5458L6 6.8484L7.6968 8.5458L8.5458 7.6968L6.8484 6L8.5458 4.3032L7.6968 3.4542L6 5.1516Z"
      fill="#868C98"
    />
  </svg>
);
