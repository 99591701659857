import { ReplaySubject } from "rxjs";
const scheduleReplayObject = new ReplaySubject(0);

Pusher.logToConsole = false;
class Service {
  pusher = null;
  channel = null;

  channelName = "schedule-channel";
  channelBind = "schedule-event";

  constructor() {
    // this.pusher = new Pusher("d7fd01c60a68b37c5af4", {
    //   cluster: "mt1",
    // });

    // this.channel = this.pusher.subscribe(this.channelName);
    // this.bindChannel();
  }

  bindChannel = () => {
    this.channel.bind(this.channelBind, (data) => {
      this.next(data);
    });
  };

  subscribe = (taskId, callBack) => {
    return scheduleReplayObject.subscribe((res) => {
      if (res?.task?._id === taskId) {
        callBack(res.task);
      }
    });
  };

  next = scheduleReplayObject.next.bind(scheduleReplayObject);
}

const SchedulePusherService = new Service();
export default SchedulePusherService;
