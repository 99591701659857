import { Fragment, useState } from "react";
import { Row } from "reactstrap";
import Dropdown from "../../wasfaty/Helper/DropdownB";
import { ScheduleTaskService } from "../../wasfaty/services";
import ExportExcel from "../../components/ExportExcel";
import "./styles.scss";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ListHeader from "../../../@core/components/ListHeader/ListHeader";
import ScheduleCardView from "./components/scheduleTask/ScheduleCardView";
import { ScheduleTaskModal } from "../../wasfaty/Models/ScheduleTaskModal";
import PaginationFooter from "../../wasfaty/components/listing/PaginationFooter";
import AuthUser from "../../wasfaty/services/AuthService";
const MySwal = withReactContent(Swal);

function ScheduleAvailability() {
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState();
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [pagination, setpagination] = useState({
    page: 0,
    pageCount: 0,
    perPage: 10,
    to: 0,
    totalPages: 0,
  });

  const [filter, setFilter] = useState({
    search: "",
    fromDate: "",
    toDate: "",
  });
  const [loading, setLoading] = useState(false);

  const getAllItems = (query) => {
    setIsLoading(true);
    setLoading(true);

    const params = {
      page: (query?.selected || 0) + 1,
      per_page: query?.perPage || 10,
      //filters
      ...filter,
    };

    ScheduleTaskService.find(params)
      .then((res) => {
        if (res?.data?.data) {
          setItems(
            res.data.data.data.map((shedule) => new ScheduleTaskModal(shedule))
          );
          const _pagination = res.data.data;
          const page = _pagination.current_page;
          const perPage = _pagination.per_page;
          const totalPages = _pagination.total;
          const pageCount = Math.ceil(totalPages / perPage);

          const to = _pagination.to;

          setpagination({
            page,
            pageCount,
            perPage,
            totalPages,
            to,
          });
        }
        setLoading(false);
        setIsLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setIsLoading(false);
      });
  };

  const DeleteData = (id) => {
    MySwal.fire({
      html: "<p class='confirm-class-head' >Are you sure you want to delete?</p>",
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1",
      },
      buttonsStyling: false,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        ScheduleTaskService.delete(id).then((res) => {
          if (res.status === 200) {
            getAllItems({
              selected: pagination.page - 1,
            });
            toast.success(res.data.data);
          } else {
            if (res.errors) {
              toast.error(error);
            }
          }
        });
      }
    });
  };
  return (
    <Fragment>
      <ListHeader
        pagination={pagination}
        exportComponent={
          null
          // <ExportExcel
          //   apiCall={true}
          //   endPoint={`${ScheduleTaskService.route}`}
          //   fileName={"ScheduleTask"}
          //   exportFilter={{ per_page: pagination.totalPages, page: 0 }}
          //   handleExportContent={({ data }) => {
          //     return ScheduleTaskService.mapExportData(data.data || []);
          //   }}
          // />
        }
        addFormUrl={
          AuthUser.isAdmin ||
          AuthUser.isSupervisor ||
          AuthUser.isRegionalRepresentatives
            ? "/Schedule-Task-Availability/Add"
            : false
        }
        dashboardUrl="/Schedule-Task-Availability/Schedule-Calander"
        dashboardText="Calender"
        filter={filter}
        setFilter={setFilter}
        getData={getAllItems}
        loading={loading}
        addButtonText="Add Schedule"
      />
      <Row>
        <Row className="d-flex align-items-center justify-content-center">
          {!items?.length && !isLoading && (
            <h5 className="w-auto">No Schedule Available!</h5>
          )}
        </Row>
        <ScheduleCardView schedules={items} DeleteData={DeleteData} />
        <PaginationFooter
          pagination={pagination}
          handlePageChange={getAllItems}
        />
      </Row>
    </Fragment>
  );
}

export default ScheduleAvailability;
