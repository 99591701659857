import { ReplaySubject } from "rxjs";
const commentSubject = new ReplaySubject(0);

Pusher.logToConsole = false;
class Service {
  pusher = null;
  channel = null;

  channelName = "my-channel";
  channelBind = "my-event";

  constructor() {
    // this.pusher = new Pusher("d7fd01c60a68b37c5af4", {
    //   cluster: "mt1",
    // });

    // this.channel = this.pusher.subscribe(this.channelName);
    // this.bindToTaskComments();
  }

  bindToTaskComments = () => {
    this.channel.bind(this.channelBind, (data) => {
      this.next(data);
    });
  };

  subscribe = (taskId, callBack) => {
    return commentSubject.subscribe((res) => {
      if (res?.data?.task_id === taskId) {
        callBack(res.data);
      }
    });
  };

  next = commentSubject.next.bind(commentSubject);
}

const PusherService = new Service();
export default PusherService;
