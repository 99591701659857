import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import date_selector from "../../../assets/images/date_selector.png";
import PinMap from "../../../assets/images/PinMap.png";
import FlatPicker from "react-flatpickr";
import moment from "moment";
import Chart from "react-apexcharts";
import DataTable from "react-data-table-component";
import get from "lodash/get";
import Select from "react-select";
import { FormattedMessage, useIntl } from "react-intl";
import { IntlContext } from "../../../utility/context/IntelContext";

import {
  Row,
  Col,
  Form,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  FormFeedback,
  CardTitle,
  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Spinner,
} from "reactstrap";
import useWindowSize from "../../wasfaty/Hooks/useWindowSize";
import {
  SiteAuditService,
  RegionService,
  IntlService,
} from "../../wasfaty/services";
import upperFirst from "lodash/capitalize";
import "./style.scss";
import AuthUser from "../../wasfaty/services/AuthService";
function Graph({ getGraphData, forRegion, currentPath }) {
  const [item, setItem] = useState({});
  const [dateRange1, setDateRange1] = useState(null);
  const [dateRange2, setDateRange2] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState();
  const intl = useIntl();
  const intlContext = useContext(IntlContext);

  const { id } = useParams();
  const sitesRef = useRef(null);

  useEffect(() => {
    // SiteService.show(id).then((res) => {
    //   setItem(res.data.data);
    //   setLoading(true);
    // });
    getDashboardData({});
    if (AuthUser.isRegionalRepresentatives) {
      setRegions(AuthUser.regions);
    } else {
      RegionService.getByMethod(forRegion).then((res) => {
        let _regions = get(res, "data.data");
        if (_regions?.length) {
          setRegions(
            _regions.map((item) => ({
              ...item,
              label: upperFirst(item.name + ""),
              value: item._id,
            }))
          );
        }
      });
    }
  }, [id]);

  const getDashboardData = (tod, fromd, reg) => {
    setLoading(true);
    let from =
      dateRange1 || fromd
        ? moment(fromd || dateRange1).format("DD-MM-YYYY")
        : "";
    let to =
      dateRange2 || tod ? moment(tod || dateRange2).format("DD-MM-YYYY") : "";
    let params = {
      region_id: reg?._id || selectedRegion?._id,
      from,
      to,
      model_name: currentPath,
    };

    getGraphData(params).then((res) => {
      if (res?.data?.data) {
        setItem(res.data.data);
        setLoading(false);
      }
    });
  };

  return (
    <>
      <Row id="graph-dashboard">
        <UncontrolledAccordion defaultOpen="1" className="site-accordion">
          <AccordionItem
            // className="bg-info"
            // style={{ backgroundColor: "#F8F8F8"  }}
            className="mb-2"
          >
            <AccordionHeader targetId="1">
              <p>
                <FormattedMessage
                  id="Sites Analysis"
                  defaultMessage={"Sites Analysis"}
                />
              </p>
            </AccordionHeader>
            <AccordionBody accordionId="1">
              <Col md="12" sm="12">
                <Row>
                  {/* <Col md="4" sm="12">
                    <Row>
                      <Col
                        md="12"
                        sm="12"
                        className="mb-2"
                        style={{ width: "100%" }}
                      >
                        <Card
                          className="mb-2 p-2 transition card-no-shadow "
                          style={{ height: 343, width: "100%" }}
                        >
                          <div style={{ width: "100%" }} ref={sitesRef}>
                            <div className="radialBar-heading">
                              <p className="custom-heading">
                                <FormattedMessage
                                  id="Total Sites"
                                  defaultMessage={"Total Sites"}
                                />
                              </p>
                              <p className="custom-heading">
                                {get(item, "totalSite.sites", 0)}
                              </p>
                            </div>
                            <div className="radialBar-rating">
                              <div className="d-flex flex-row mb-2">
                                <div className="s-round" />
                                <div className="ml-10">
                                  <p className="submitted">
                                    <FormattedMessage
                                      id="Submitted"
                                      defaultMessage={"Submitted"}
                                    />
                                  </p>
                                  <p className="submitted-value">
                                    {get(item, "totalSite.submitted", 0)}%
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex flex-row ">
                                <div className="s-round-no" />
                                <div className="ml-10">
                                  <p className="submitted">
                                    <FormattedMessage
                                      id="Not Submitted"
                                      defaultMessage={"Not Submitted"}
                                    />
                                  </p>
                                  <p className="submitted-value">
                                    {get(item, "totalSite.notSubmitted", 0)}%
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                marginLeft:
                                  intlContext.locale === "en" ? 100 : 0,
                                marginRight:
                                  intlContext.locale !== "en" ? 100 : 0,
                              }}
                            >
                              <TotalSites
                                columnRef={sitesRef}
                                submitted={get(item, "totalSite.submitted", 50)}
                                notSubmitted={get(
                                  item,
                                  "totalSite.notSubmitted",
                                  50
                                )}
                              />
                            </div>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </Col> */}
                  <Col md="12" sm="12">
                    <Card
                      className="mb-2 p-2 transition card-no-shadow "
                      style={{ height: 343 }}
                    >
                      <CardTitle
                        className="card-heading"
                        tag="h4"
                        style={{ position: "absolute" }}
                      >
                        <FormattedMessage
                          id="Total Submissions"
                          defaultMessage={"Total Submissions"}
                        />
                        <p className="submitted" style={{ marginTop: 2 }}>
                          <FormattedMessage
                            id="Submissions From"
                            defaultMessage={"Submissions From"}
                          />{" "}
                          {get(item, "totalSubmissions.submissions")}{" "}
                          <FormattedMessage
                            id="sites"
                            defaultMessage={"sites"}
                          />
                        </p>
                      </CardTitle>
                      <CardBody className="mt-2">
                        <TotalSubmissions
                          data={get(item, "totalSubmissions.0")}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                  {/* <Col md="4" sm="12">
                    <SiteTracker data={get(item, "siteTracker")} />
                  </Col> */}
                </Row>
              </Col>
            </AccordionBody>
          </AccordionItem>
        </UncontrolledAccordion>{" "}
        <Col md="12">
          <Row className="d-flex justify-content-between">
            <Col md="8" sm="12">
              <Row>
                <Col md="6" sm="12" className="mb-2" style={{ width: 295 }}>
                  <FlatPicker
                    className="form-control date-picker"
                    options={{
                      dateFormat: "d-m-Y",
                      mode: "range",
                    }}
                    onClose={(e) => {
                      try {
                        setDateRange1(e[0].toISOString());
                        setDateRange2(e[1]?.toISOString?.() || 0);
                        getDashboardData(
                          e[1]?.toISOString?.() || 0,
                          e[0].toISOString()
                        );
                      } catch (error) {}
                    }}
                    render={({ defaultValue, value, ...props }, ref) => {
                      return (
                        <>
                          <div className="flat-picker d-flex align-items-center">
                            <div className="m-1">
                              <img src={date_selector} />
                            </div>
                            <div>
                              <p className="m-0">
                                <FormattedMessage
                                  id="Date Range"
                                  defaultMessage={"Date Range"}
                                />
                              </p>
                              <p className="m-0 selected-range">
                                {dateRange1 &&
                                  moment(dateRange1).format("DD MMM YY")}

                                {dateRange2 &&
                                  " - " +
                                    moment(dateRange2).format("DD MMM YY")}
                              </p>
                            </div>
                          </div>
                          <input
                            {...props}
                            defaultValue={defaultValue}
                            ref={ref}
                            style={{ background: "transparent" }}
                          />
                        </>
                      );
                    }}
                  />
                </Col>
                <Col md="6" sm="12" style={{ width: 315 }} className="mb-2">
                  <Select
                    placeholder={intl.formatMessage({
                      id: "Select Region",
                      defaultMessage: "Select Region",
                    })}
                    name="type"
                    value={selectedRegion}
                    onChange={(e) => {
                      setSelectedRegion(e);
                      getDashboardData(false, false, e);
                    }}
                    options={regions}
                    className="react-select-paginate"
                  />
                </Col>{" "}
              </Row>{" "}
            </Col>{" "}
            <Col
              md="2"
              sm="12"
              className="mb-2 d-flex justify-content-center align-items-center"
            >
              {isLoading && (
                <Spinner
                  size="lg"
                  // color="#F6F8FA"
                  style={{ color: "#F6F8FA" }}
                />
              )}
            </Col>
          </Row>
        </Col>
        <Col md="12" sm="12">
          <Row>
            <Col md="6" sm="12">
              <SubmissionPerRegion data={get(item, "submissionPerRegion")} />
            </Col>
            <Col md="6" sm="12">
              <SubmissionsPerPmo data={get(item, "pmo")} />
            </Col>
          </Row>
        </Col>
        <Col>
          <SubmissionPerRegionTable data={get(item, "submissionPerSite")} />
        </Col>
      </Row>
    </>
  );
}

export default Graph;

const TotalSites = ({ columnRef, submitted, notSubmitted }) => {
  const [width, height] = useWindowSize();
  let percentageWidth = (columnRef.current?.offsetWidth || 0) * 0.75;

  const data = {
    series: [notSubmitted, submitted],
    options: {
      chart: {
        // height: 50,
        type: "radialBar",
      },
      fill: {
        opacity: 1.5,
        colors: ["#1C2346", "#10A945"],
      },

      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "22px",
            },
            value: {
              show: false,
              fontSize: "16px",
            },
            total: {
              show: false,
            },
          },
        },
      },
      labels: ["", ""],
    },
  };
  return (
    <Chart
      options={data.options}
      series={data.series}
      type="radialBar"
      width={percentageWidth > 400 ? 400 : percentageWidth}
    />
  );
};

const TotalSubmissions = ({ data }) => {
  const intl = useIntl();

  const settings = {
    series: [
      {
        name: "Submissions",
        data: [
          get(data, "Monday", 0),
          get(data, "Tuesday", 0),
          get(data, "Wednesday", 0),
          get(data, "Thursday", 0),
          get(data, "Friday", 0),
          get(data, "Saturday", 0),
          get(data, "Sunday", 0),
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      markers: {
        size: 5,
        shape: "circle",
      },
      colors: ["#10A945"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: [2],
      },
      title: {
        show: false,
      },
      grid: {
        row: {
          colors: ["white"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: IntlService.m("Days"),
          offsetY: -20,
        },
        categories: [
          intl.formatMessage({ id: "Monday", defaultMessage: "Monday" }),
          intl.formatMessage({ id: "Tuesday", defaultMessage: "Tuesday" }),
          intl.formatMessage({ id: "Wednesday", defaultMessage: "Wednesday" }),
          intl.formatMessage({ id: "Thursday", defaultMessage: "Thursday" }),
          intl.formatMessage({ id: "Friday", defaultMessage: "Friday" }),
          intl.formatMessage({ id: "Saturday", defaultMessage: "Saturday" }),
          intl.formatMessage({ id: "Sunday", defaultMessage: "Sunday" }),
        ],
      },
    },
  };
  return (
    <Chart
      options={settings.options}
      series={settings.series}
      type="line"
      height={270}
    />
  );
};

const SubmissionsPerPmo = ({ data }) => {
  const intl = useIntl();

  data =
    data && typeof data === "object"
      ? Object.entries(data).map(([name, value]) => {
          return {
            name: name,
            value,
          };
        })
      : [];

  const settings = {
    series: [
      {
        data: data.map((i) => i.value),
      },
    ],
    options: {
      colors: ["#10A945"],
      chart: {
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        title: {
          text: IntlService.m("Inspcctors Names"),
          // offsetY: -10,
        },
        categories: data.map((i) =>
          intl.formatMessage({ id: i.name, defaultMessage: i.name })
        ),
      },
    },
  };
  return (
    <Card className="mb-2 p-2 transition shadow-none" style={{ height: 343 }}>
      <CardTitle
        className="card-heading"
        tag="h4"
        style={{ position: "absolute" }}
      >
        <FormattedMessage
          id="Submission Per Inspector"
          defaultMessage={"Submission Per Inspector"}
        />
      </CardTitle>
      <CardBody className="mt-2">
        <Chart
          options={settings.options}
          series={settings.series}
          type="bar"
          height={270}
        />
      </CardBody>
    </Card>
  );
};

const SiteTracker = ({ data }) => {
  const intl = useIntl();

  data =
    data && typeof data === "object"
      ? Object.entries(data).map(([name, value]) => {
          return {
            name: intl.formatMessage({ id: name, defaultMessage: name }),
            value,
          };
        })
      : [];

  const settings = {
    series: [
      {
        name: "",
        data: data.map((i) => i.value),
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      colors: ["#1C2346"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        width: [2],
      },
      title: {
        show: false,
      },
      grid: {
        row: {
          colors: ["white"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        title: {
          text: IntlService.m("Date"),
          offsetY: -15,
        },
        categories: data.map((i) => i.name),
      },
    },
  };
  return (
    <Card
      className="mb-2 p-2 transition card-no-shadow"
      style={{ height: 343 }}
    >
      <CardTitle
        className="card-heading"
        tag="h4"
        style={{ position: "absolute" }}
      >
        <FormattedMessage id="Site Tracker" defaultMessage={"Site Tracker"} />
      </CardTitle>
      <CardBody className="mt-2">
        <Chart
          options={settings.options}
          series={settings.series}
          type="line"
          height={270}
        />
      </CardBody>
    </Card>
  );
};

const SubmissionPerRegion = ({ data }) => {
  const intl = useIntl();

  data =
    data && typeof data === "object"
      ? Object.entries(data).map(([name, value]) => {
          return {
            name: intl.formatMessage({ id: name, defaultMessage: name }),
            value,
          };
        })
      : [];

  return (
    <Card
      className="mb-2 p-2 submission-per-region transition shadow-none"
      style={{ height: 343 }}
    >
      <p className="card-heading ">
        <FormattedMessage
          id="Submission Per Region"
          defaultMessage={"Submission Per Region"}
        />
      </p>
      <DataTable
        noHeader
        columns={[
          {
            name: IntlService.m("Names"),
            selector: "name",
          },
          {
            name: IntlService.m("Submissions"),
            selector: "value",
          },
        ]}
        data={data}
        className="react-dataTable s-table "
      />
    </Card>
  );
};

const SubmissionPerRegionTable = ({ data }) => {
  const intl = useIntl();

  data = data || [];
  return (
    <Card className="mb-2 sub-full-table shadow-none">
      <p>
        <FormattedMessage
          id="Submission Per Region"
          defaultMessage={"Submission Per Region"}
        />
      </p>
      <DataTable
        noHeader
        columns={[
          {
            name: IntlService.m("Sr.No"),
            sortable: true,
            selector: "_id",
            width: "150px",
            cell: (row) => (
              <div className="id">
                <p style={{ backgroundColor: "unset" }}>{row._id}</p>
              </div>
            ),
          },
          {
            name: intl.formatMessage({
              id: "Region Name",
              defaultMessage: "Region Name",
            }),
            sortable: true,
            selector: "name",
          },
          {
            name: intl.formatMessage({
              id: "Submissions",
              defaultMessage: "Submissions",
            }),
            sortable: true,
            selector: "value",
          },
          {
            name: intl.formatMessage({
              id: "Graph",
              defaultMessage: "Graph",
            }),
            sortable: true,
            selector: "name",
            width: "300px",
            cell: (row) => <SubmissionGraph data={row.sites} />,
          },
        ]}
        data={data.map(({ region }, index) => ({
          _id: index + 1,
          name: region.name,
          value: region.regionSubmission,
          sites: region.sites,
        }))}
        className="react-dataTable p-2"
      />
    </Card>
  );
};

const SubmissionGraph = ({ data }) => {
  const intl = useIntl();

  data =
    data && typeof data === "object"
      ? Object.entries(data).map(([name, value]) => {
          return {
            name: intl.formatMessage({ id: name, defaultMessage: name }),
            value,
          };
        })
      : [];

  const settings = {
    series: [
      {
        name: "",
        data: data.map((i) => i.value),
      },
    ],
    options: {
      hover: {
        filter: {
          type: "none",
        },
      },
      responsive: [
        {
          breakpoint: 1000,
          options: {
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],

      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      colors: ["#10A945"],
      dataLabels: {
        show: false,
        enabled: false,
      },
      stroke: {
        curve: "straight",
        width: [2],
      },
      title: {
        show: false,
      },
      grid: {
        show: false,
      },

      xaxis: {
        show: false,
        categories: data.map((i) => i.name),
        labels: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
    },
  };
  return (
    <Chart
      options={settings.options}
      series={settings.series}
      type="line"
      // height={100}
    />
  );
};
