import { Singleton, Resources } from "../Resource";
import { SC } from "../Api/serverCall";
import startCase from "lodash/startCase";
import get from "lodash/get";
import XLSX from "xlsx";

import { formFields } from "../Models/InspectionModal";
import moment from "moment";
class Service extends Resources {
  route = "selfAssessment";
  routes = {
    find: "",
    create: "/create",
    show: "/show",
    update: "/update",
    delete: "/delete",
  };

  constructor() {
    super(arguments);
  }

  getHeaders() {
    return [
      { title: "Region", dataIndex: "region" },
      { title: "User", dataIndex: "user" },
      { title: "Pharmacy", dataIndex: "site" },
      { title: "City", dataIndex: "city" },
      { title: "Street name", dataIndex: "street_name" },
      { title: "District Name", dataIndex: "district_name" },
      { title: startCase("longitude"), dataIndex: "longitude" },
      { title: startCase("latitude"), dataIndex: "latitude" },
      {
        title: "The full name of the institution",
        dataIndex: "name_of_the_institution",
      },
      {
        title: "The name of the owner of the company",
        dataIndex: "owner_name",
      },
      { title: "Institution email", dataIndex: "institution_email" },
      // {
      //   title: "Is the facility CBAHI certified?",
      //   dataIndex: "cbahi_certified",
      // },
      // {
      //   title: "Facility license number",
      //   dataIndex: "facility_license_number",
      // },
      {
        title: "Medicines",
        dataIndex: "medicine",
      },
      {
        title: "Created At",
        dataIndex: "created_at",
      },
    ];
  }

  mapExportData(data, _auditMethod = "") {
    data = data.map((item) => {
      item.region = get(item, "region.name", "");

      item.site = (item.site?._id ? item.site : item.obligation_site) || {};

      let name_en = item.site?.name;
      let name_ar = item.site?.name_ar;
      let name = name_ar || name_en || "N/A";
      if (name_en && name_ar) {
        name = name_en + " - " + name_ar;
      }

      item.license = item?.site?.license || item?.site?.licenseNumber || "N/A";
      item.site = name;

      item.user = get(item, "user.name", "");
      item.medicine = get(item, "medicine", "")
        .map((item) =>
          Object.entries(item)
            .map((inerItem) => {
              if (inerItem[0] == "medicine")
                return `${inerItem[0]} : ${inerItem[1]?.name}`;
              return `${inerItem[0]} : ${inerItem[1]}`;
            })
            .join(",")
        )
        .join("|");

      item.created_at = moment(item.created_at).format("lll");

      return item;
    });

    return {
      headers: this.getHeaders(),
      data,
    };
  }
}

export default Singleton(Service);
