// -- Medicine Object -- ||
export const MedicineObj = {
  name: "",
  generic: "",
};
// -- error show 401 -- //
export const ErrorShow = (error) => {
  if (error?.message === "Request failed with status code 401") {
    return false;
  } else {
    return "Something went  wrong!";
  }
};
// -- Module Object -- ||
export const moduleeObj = {
  name: "",
};
// -- Sites Object -- ||
export const sitesObj = {
  name: "",
  region: [],
  siteTypes: [],
  major: "",
  moh: "",
};
// -- demo options --- \\
export const demiOptions = [
  { value: "ocean", label: "Ocean" },
  { value: "blue", label: "Blue" },
  { value: "purple", label: "Purple" },
  { value: "red", label: "Red" },
  { value: "orange", label: "Orange" },
];
// -- wasfaty avaiable options --- \\
export const wableOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];
// -- Sites Object -- ||
export const phcchecklist = {
  region_id: null,
  city: "",
  site_id: null,
  phc_representative: "",
  phone: "",
  position: "",
  wasfaty_availability: "",
  wasfaty_activation: false,
  wasfaty_not_available: "",
  dispense_medications: false,
};
export const not_permission_text =
  "you don't have access to this module please contact your admin.";
