import React from "react";
import { Col } from "reactstrap";
import { IntlService } from "../wasfaty/services";

const TextView = ({ item, attribute, title, value, classes = ""}) => {
  if (!item[attribute]) return null;

  return (
    <Col md="4" sm="12" className={classes}> 
      <p>{IntlService.m(title)}</p>
      <h4 className={classes ? "text-white":null}>{IntlService.m(value || item[attribute] || "-")}</h4>
    </Col>
  );
};

export default TextView;
