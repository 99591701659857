import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Spinner,
  InputGroup,
  Label,
  InputGroupText,
} from "reactstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import toast from "react-hot-toast";

import { SC } from "../wasfaty/Api/serverCall";
import { validateFields } from "./ValidateFields";
import { FieldValidation } from "./RenderFeedback";
import "./newStyle.scss";

import { emailValidator, phoneValidator } from "../wasfaty/Helper/utils";
import PhoneMask from "../forms/form-elements/input-mask/PhoneMask";
import { IntlService } from "../wasfaty/services";
import Header from "./Components/Header";
import forgetPasswordAvatar from "./assets/forgetPasswordAvatar.png";
import ContentSection from "./Components/ContentSection";
import Footer from "./Components/Footer";
import { info, mail, saudiFlag } from "./assets/svg";
import InstituteModal from "./Components/InstituteModal";
import { rtlSupportStyles } from "./authThemeConfig";

const MySwal = withReactContent(Swal);

const dataTorender = [
  { label: "Name Arabic", key: "name_ar" },
  { label: "License Number", key: "licenseNumber" },
  { label: "License Issue Date", key: "license_issue_date" },
  { label: "License Expiry Date", key: "license_expiry_date" },
  { label: "Facility type arabic", key: "facility_type_arabic" },
  { label: "Facility Type", key: "facilityType" },
  { label: "Subtype", key: "subtype" },
  { label: "Narcotic drugs", key: "narcotic_drugs" },
  { label: "Specialization", key: "specialization" },
  { label: "Number of beds", key: "number_of_beds" },
  { label: "Additional activities", key: "additional_activities" },
  { label: "License Status", key: "license_status" },
  { label: "License Type", key: "license_type" },
  { label: "City", key: "city" },
  { label: "Region", key: "region" },
  { label: "Directorate", key: "directorate" },
  { label: "Latitude", key: "latitude" },
  { label: "Longitude", key: "longitude" },
];
const InstituteNumber = () => {
  const [formData, setFormData] = useState({ number: "" });
  const [data, setdata] = useState(null);
  const [email, setEmail] = useState("");
  const [validation, setValidation] = useState({ number: [], email: [] });
  const [isValidationError, setIsValidationError] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleChange = (state, value) => {
    setFormData({ ...formData, [state]: value });
    handleValidationOnChange({ key: state, value });
  };

  const handleValidationOnChange = ({ key, value }) => {
    const response = validateFields({
      value,
      not: true,
      isnumber: key === "number",
      isemail: key === "email",
    });
    setValidation({ ...validation, [key]: response });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = validateFields({
      value: formData.number,
      not: true,
      isnumber: true,
    });

    if (response.length > 0) {
      setValidation({ number: response, email: validation.email });
      setIsValidationError(true);
    } else {
      setIsValidationError(false);
      setLoading(true);

      SC.getCall({
        url: "ObligationSite-licenceGet",
        params: { licenseNumber: formData.number },
      }).then((res) => {
        if (res.status == 200) {
          setdata(res.data.data);

          setLoading(false);
          MySwal.fire({
            html: (
              <div
                className="institute_container d-flex flex-column justify-content-between align-items-center"
                // dir={IntlService.isRtl ? "rtl" : "ltr"}
              >
                <InstituteModal
                  item={res?.data?.data}
                  dataTorender={dataTorender}
                />

                <div className="w-100">
                  <div className="my-1 text-start " style={{ marginLeft: 1 }}>
                    <Label className="emailLabel">
                      {IntlService.m("Email Address")}
                    </Label>
                    <InputGroup className="w-100">
                      <figure
                        style={{
                          position: "absolute",
                          top: 9,
                          right: IntlService.isRtl ? 15 : null,
                          left: IntlService.isRtl ? null : 15,
                          zIndex: 999,
                        }}
                      >
                        {mail}
                      </figure>
                      <Input
                        id="swal-input1"
                        className="loginFields relative"
                        style={{
                          paddingLeft: 40,
                          paddingRight: 40,
                        }}
                        type="email"
                        placeholder={IntlService.m("Email")}
                      />
                    </InputGroup>
                  </div>

                  <PhoneMask icon={saudiFlag} />
                </div>
              </div>
            ),
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: IntlService.m("Okay"),
            cancelButtonText: IntlService.m("Cancel"),
            customClass: {
              popup: "show-model",
              confirmButton: "btn btn-primary ",
              cancelButton: "btn btn-outline-danger ms-1",
              validationMessage: "validationMessage",
            },
            allowOutsideClick: false,
            buttonsStyling: false,

            preConfirm: function () {
              return new Promise(function (resolve, rej) {
                if (
                  !emailValidator(
                    document.getElementById("swal-input1")?.value
                  ) ||
                  !phoneValidator(document.getElementById("inputmask")?.value)
                ) {
                  MySwal.showValidationMessage(
                    "Enter a value in both fields and in correct format!"
                  );
                  MySwal?.enableButtons();
                } else {
                  MySwal.resetValidationMessage();
                  resolve([
                    document.getElementById("swal-input1")?.value,
                    document.getElementById("inputmask")?.value,
                  ]);
                }
              });
            },
          }).then(({ isConfirmed, value }) => {
            if (isConfirmed) {
              let data = {
                institutionEmail: value[0],
                institutionPhone: value[1],
                licenseNumber: formData.number,
              };

              SC.postCall({ url: "ObligationSite-userCreate", data }).then(
                (res) => {
                  if (res.status == 200) {
                    toast.success(res.data.data);

                    navigate("/login");
                  } else toast.error(res.response.data.error);
                }
              );
            }
          });
        } else {
          toast.error(res.response.data.error);
        }

        setLoading(false);
      });
    }
  };

  const handleConfirmation = () => {
    // Handle confirmation logic
  };

  return (
    <div
      className="authLayout_container"
      dir={IntlService.isRtl ? "rtl" : "ltr"}
    >
      <div className="form_section">
        <Header />
        <Form className="d-flex justify-content-center">
        <div className="login_form">
            <div className="login-header">
              <img src={forgetPasswordAvatar} width={110} />
              <p className="heading">{IntlService.m("Institute Number")}</p>
              <p className="info">
                {IntlService.m("Enter your desire institute number.")}
              </p>
            </div>

            <hr className="line-devider" />

            <div>
              <div className="mb-1">
                <InputGroup>
                  <Input
                    type="number"
                    style={{ borderRadius: 10 }}
                    className="loginFields"
                    placeholder={IntlService.m("Enter license number")}
                    value={formData.number}
                    onChange={(e) => handleChange("number", e.target.value)}
                    invalid={validation.number.length && isValidationError}
                  />
                </InputGroup>
                <p className="info-content mb-2">
                  <span>{info}</span>
                  {IntlService.m(
                    "Enter the number which you want to registered."
                  )}
                </p>
                {/* {isValidationError && (
                  <FieldValidation
                    validations={validation.number}
                    isTransparent={true}
                  />
                )} */}
              </div>
            </div>

            <Button
              type="submit"
              // color="primary button-main"
              block
              className="p-1 mt-2 authButton"
              onClick={handleSubmit}
              disabled={loading}
            >
              {!loading ? (
                IntlService.m("Check")
              ) : (
                <span>
                  <Spinner size="sm" className="mx-1" />

                  {IntlService.m("Checking")}
                </span>
              )}
            </Button>

            <Button
              // color="primary button-main"
              block
              outline
              className="p-1 mt-2 backButton"
              // onClick={handleSubmit}
              disabled={loading}
              onClick={() => navigate("/login")}
            >
              {IntlService.m("Return to login")}
            </Button>
          </div>
        </Form>
        <Footer />
      </div>
      <ContentSection />
    </div>
  );
};

export default InstituteNumber;
