import {
  Form,
  Label,
  Input,
  Button,
  FormFeedback,
  Spinner,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import loginUserAvatar from "./assets/loginUserAvatar.png";
import otpAvatar from "./assets/otpAvatar.png";

import "./newStyle.scss";
import AlertDismissable from "./assets/Alert";
import { SC } from "../wasfaty/Api/serverCall";
import OTPInput from "react-otp-input";

import toast from "react-hot-toast";
import { arrowRight, eye, headPhones, lock, mail } from "./assets/svg";
import { IntlService } from "../wasfaty/services";
import { useDispatch } from "react-redux";
import { rtlSupportStyles } from "./authThemeConfig";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import ContentSection from "./Components/ContentSection";
import ReCAPTCHA from "react-google-recaptcha";
const disableOtp = true
let disableCaptcha = false

const Login = () => {
  const params = new URLSearchParams(new URL(window.location.href).search);
  const isQA = !!params.get("isQA");
  disableCaptcha = disableCaptcha || isQA;

  const dispatch = useDispatch();
  const [validation, setValidation] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPass, setshowPass] = useState(false);
  const [Message, setMessage] = useState("");
  const [counter, setCounter] = useState(-100);
  const counterRef = useRef();
  counterRef.current = counter;
  const [Data, setData] = useState({
    email: "",
    password: "",
    otp: "",
    otpState: false,
    captcha: disableCaptcha,
  });
  const navigate = useNavigate();

  const handlechange = (state, value) => {
    setData({ ...Data, [state]: value });
  };

  useEffect(() => {
    setInterval(() => {
      if (counterRef.current > 0) {
        setCounter((prev) => prev - 1);
      }
    }, 1000);
  }, []);

  const sendOtp = () => {
    setCounter(60);
    SC.postCallWithoutAuth({ url: "sendOtp", data: Data }).then((response) => {
      if (response.status === 200 && response.data) {
        toast.success(response.data.data);
        setData({ ...Data, otpState: true });
        setLoading(false);
        setVisible(false);
      } else {
        setCounter(0);
        toast.error(response?.response?.data?.error);

        setLoading(false);
      }
    });
  };

  const handleSubmit = async (data) => {
    data?.preventDefault?.();

    if (Data.email === "" || Data.password === "" || !Data.captcha) {
      if (!Data.captcha) {
        toast.error("Please fill the captcha!");
      }

      setValidation(true);
    } else {
      setLoading(true);
      setValidation(false);

      if (Data.otpState || disableOtp) {
        SC.postCallWithoutAuth({
          url: disableOtp ? "login" : "loginWithOtp",
          data: Data,
        }).then((response) => {
          if (response.status === 200 && response.data) {
            localStorage.setItem(
              "accessToken",
              JSON.stringify(response?.data?.accessToken)
            );

            localStorage.setItem(
              "refreshToken",
              JSON.stringify(response?.data?.refreshToken)
            );

            SC.getCall({ url: "token" }).then((res) => {
              localStorage.setItem(
                "userData",
                JSON.stringify(res.data.data.user[0])
              );
              setLoading(false);
              window.location.reload(false);
            });
            setVisible(false);
          } else {
            toast.error(response?.response?.data?.error);
            // setMessage(response?.response?.data?.error);
            setLoading(false);
          }
        });
      } else {
        sendOtp();
      }
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        handleSubmit();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleSubmit]);

  return (
    <div
      className="authLayout_container"
      dir={IntlService.isRtl ? "rtl" : "ltr"}
    >
      <div className="form_section">
        <Header />
        <Form className="d-flex justify-content-center" style={{flexDirection:"row"}}>
          <div className="login_form">
            <div className="login-header">
              <img
                src={Data.otpState ? otpAvatar : loginUserAvatar}
                width={110}
              />
              <p className="heading">
                {Data.otpState && !disableOtp
                  ? IntlService.m("Enter Verification Code")
                  : IntlService.m("Login to your account")}
              </p>
              <p className="info">
                {Data.otpState && !disableOtp ? (
                  <>
                    {IntlService.m("We’ve sent a code to ")}
                    <strong>{Data.email}</strong>
                  </>
                ) : (
                  IntlService.m("Enter your details to login.")
                )}
              </p>
            </div>
            <div className="">
              <AlertDismissable
                visible={visible}
                setVisible={setVisible}
                msg={Message?.toString?.()}
                invalid={IntlService.m("Email or Password invalid")}
              />
            </div>

            <hr className="line-devider" />

            {!Data.otpState || disableOtp ? (
              <div>
                <div className="mb-1">
                  <Label className="lables">
                    {IntlService.m("Email Address*")}
                  </Label>
                  <InputGroup style={{ boxShadow: "none" }}>
                    <Input
                      className="loginFields relative"
                      style={{
                        paddingLeft: 40,
                        paddingRight: 40,
                      }}
                      type="email"
                      placeholder={IntlService.m("Email")}
                      onChange={(e) =>
                        handlechange("email", e.target.value.toLowerCase())
                      }
                      invalid={Data.email === "" && validation ? true : false}
                    />

                    <figure
                      className={
                        Data.email === "" && validation
                          ? "loginfields-icon-invalid"
                          : null
                      }
                      style={{
                        position: "absolute",
                        top: 8,
                        right: IntlService.isRtl ? 15 : null,
                        left: IntlService.isRtl ? null : 15,
                        zIndex: 999,
                      }}
                    >
                      {mail}
                    </figure>
                    {/* <FormFeedback
                      invalid={
                        Data.email === "" && validation ? "true" : "false"
                      }
                    >
                      {IntlService.m("Please enter email address")}
                    </FormFeedback> */}
                  </InputGroup>
                </div>

                <div className="mb-1">
                  <Label className="lables">{IntlService.m("Password*")}</Label>
                  <InputGroup style={{ boxShadow: "none" }}>
                    <figure
                      className={
                        Data.password === "" && validation
                          ? "loginfields-icon-invalid"
                          : null
                      }
                      style={{
                        position: "absolute",
                        top: 8,
                        right: IntlService.isRtl ? 12 : null,
                        left: IntlService.isRtl ? null : 12,
                        zIndex: 999,
                      }}
                    >
                      {lock}
                    </figure>
                    <Input
                      className="loginFields relative"
                      // autoComplete="new-password"
                      style={{
                        paddingLeft: 40,
                        paddingRight: 40,
                      }}
                      type={showPass ? "text" : "password"}
                      placeholder={IntlService.m("Password")}
                      onChange={(e) => handlechange("password", e.target.value)}
                      invalid={
                        Data.password === "" && validation ? true : false
                      }
                    />

                    {Data.password ? (
                      <figure
                        className="position-absolute cursor-pointer"
                        onClick={() => {
                          setshowPass((prev) => !prev);
                        }}
                        style={{
                          zIndex: 999,
                          top: 8,
                          right: IntlService.isRtl ? null : 10,
                          left: IntlService.isRtl ? 10 : null,
                        }}
                      >
                        {eye}
                      </figure>
                    ) : null}

                    {/* <FormFeedback
                      invalid={
                        Data.password === "" && validation ? "true" : "false"
                      }
                    >
                      {IntlService.m("Please enter Password")}
                    </FormFeedback> */}
                  </InputGroup>
                </div>

                {!disableCaptcha && (
                  <div className="d-flex justify-content-center">
                    <ReCAPTCHA
                      sitekey="6LcaG3MmAAAAAHKeyUUldKw96BYmXPjX6uNLEFXV"
                      onChange={(e) => handlechange("captcha", true)}
                      onExpired={(e) => handlechange("captcha", false)}
                    />
                  </div>
                )}

                <div>
                  {!Data.otpState || disableOtp ? (
                    <Link to="/forgot-password" className="forget_password">
                      <p className="my-2">
                        {IntlService.m("Forgot Password?")}
                      </p>
                    </Link>
                  ) : counter > 0 ? (
                    <small style={{ fontSize: 14 }}>{counter}</small>
                  ) : (
                    <small onClick={sendOtp} style={{ cursor: "pointer" }}>
                      {IntlService.m("Resend OTP")}
                    </small>
                  )}
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center mb-4">
                <OTPInput
                  renderInput={(props) => <input {...props} />}
                  value={Data.otp}
                  onChange={(e) => handlechange("otp", e)}
                  numInputs={6}
                  inputType="number"
                  skipDefaultStyles={true}
                  inputStyle={{
                    width: "15%",
                    height: "60px",
                    fontSize: 19,
                    fontWeight: 700,
                    marginLeft: "10px",
                    borderRadius: "10px",
                    maxWidth: "60px",
                    borderColor: "#e2e4e9",
                  }}
                />
              </div>
            )}

            {Data.otpState ? (
              <>
                <Button
                  type="submit"
                  // color="primary button-main"
                  block
                  className="p-1 authButton"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {!loading ? (
                    IntlService.m("Verify")
                  ) : (
                    <span>
                      <Spinner size="sm" className="mx-1" />
                    </span>
                  )}
                </Button>
                <Button
                  type="submit"
                  // color="primary button-main"
                  block
                  outline
                  className="p-1 mt-2 backButton"
                  onClick={() => handlechange("otpState", false)}
                  disabled={loading}
                >
                  {IntlService.m("Go Back")}
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="submit"
                  // color="primary button-main"
                  block
                  className="p-1 authButton"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {!loading ? (
                    IntlService.m("Login")
                  ) : (
                    <span>
                      <Spinner size="sm" className="mx-1" />

                      {IntlService.m("Signing in")}
                    </span>
                  )}
                </Button>

                <div className="d-flex justify-content-center mt-2">
                  <Link to="/institute-register" className="register_facility">
                    <div className="d-flex flex-row">
                      <p style={{ margin: 0 }}>
                        {IntlService.m("Register Facility")}
                      </p>
                      <div
                        className="ms-1"
                        style={{
                          transform: IntlService.isRtl
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        }}
                      >
                        {arrowRight}
                      </div>
                    </div>
                  </Link>
                </div>
              </>
            )}
          </div>
        </Form>
        <Footer />
      </div>
      <ContentSection />
    </div>
  );
};

export default Login;
