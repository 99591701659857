// ** React Imports
import { Outlet } from "react-router-dom";

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from "@layouts/VerticalLayout";

// ** Menu Items Array
import RoutingService from "../views/wasfaty/services/RoutingService";

const VerticalLayout = (props) => {

  return (
    <Layout menuData={RoutingService.sideMenu} {...props}>
      <Outlet />
    </Layout>
  );
};

export default VerticalLayout;
