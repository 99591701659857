import { Singleton, Resources } from "../Resource";

class Service extends Resources {
  route = "draft";
  routes = {
    find: "/",
    create: "/create",
  };

  constructor() {
    super(arguments);
  }
}

export default Singleton(Service);
