import React, { useEffect, useState } from "react";
import "./style.scss";
import { ModuleService } from "../../wasfaty/services";
import { TabView, TabPanel } from "primereact/tabview";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import DynamicFormGraphs from "./DynamicFormGraphs";

function ComplianceDashboard() {
  const [allForms, setAllForms] = useState([]);

  const getForms = async () => {
    let data = await ModuleService.getDynamicForms();
    setAllForms(data.filter((form) => form.status));
  };

  useEffect(() => {
    getForms();
  }, []);

  if (!allForms) return null;

  return (
    <>
      <TabView scrollable color="red">
        {allForms.map((tab, index) => {
          return (
            <TabPanel header={tab.name} key={index}>
              <DynamicFormGraphs form_id={tab._id} form={tab} />
            </TabPanel>
          );
        })}
      </TabView>
    </>
  );
}

export default ComplianceDashboard;