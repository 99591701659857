import { makeAutoObservable } from "mobx";
import {
  phoneValidator,
  emailValidator,
  getValidPhone,
  StepsAlert,
} from "../Helper/utils";

import toast from "react-hot-toast";
import Inspector from "./InspectorModel";
import FileUploadModal from "./FileUploadModel";
import {
  ClusterService,
  IntlService,
  MedicationAvailabilityServices,
  ModuleService,
  SiteService,
} from "../services";
import uniqBy from "lodash/uniqBy";
import debounce from "lodash/debounce";
import startCase from "lodash/startCase";
import DraftModal from "./DraftModel";
import { getUserData } from "@utils";
import { Schema } from "@formily/json-schema";
import Staff from "./Staff";
import { scrollToRequired } from "../Helper/utils";
import { ModalMixin } from "./Utility";
import MedicineModel from "./MedicineSelectionForMadicineAvailbilityModel";
import ComplianceMember from "./ComplianceMemberModel";
import { steperForm } from "./AllFormNamesAndSteps";
import moment from "moment";
import ScheduleSelfAssessmentServices from "../services/ScheduleSelfAssessmentServices";
export const formFields = {};

const flatten = function (form) {
  return Object.entries(form)
    .map(([key, attributes]) =>
      attributes.map((item) => {
        item.parent = key;
        return item;
      })
    )
    .flatMap((attribute) => attribute);
};

class DynamicFormModal {
  _id = "";
  is_inspection_engine = true;
  name = "";
  value = "";
  allSteps = [];
  isLoading = false;

  constructor(props) {
    this._id = props._id;
    this.name = props.name;
    this.value = props._id;
    makeAutoObservable(this);
  }

  setSteps = async () => {
    this.isLoading = true;
    let res = await ModuleService.getSteps(this._id);
    if (res.schema.schema) {
      this.allSteps = this.parseSteps(res.schema.schema);
    }
    this.isLoading = false;
  };
  parseSteps = (orignalSchema) => {
    orignalSchema = new Schema(orignalSchema);
    const steps = [];

    let mapProps = (s) => {
      s.mapProperties((schema, name) => {
        if (Object.keys(schema?.properties || {})) {
          mapProps(schema);
        }

        if (schema["x-component"]?.indexOf("StepPane") > -1) {
          steps.push({
            value: name,
            label: schema["x-component-props"]["title"],
            // schema,
          });
        }
      });
    };

    mapProps(orignalSchema);
    return steps;
  };

  get steps() {
    return this.allSteps;
  }
}

export class SecduleSelfAssessmentModal {
  class_name = "ScheduleSelfAssessmentModal";
  isValidation = false;
  isSiteFetching = false;
  isSubmit = false;
  isReview = false;
  isEdit = false;
  isAllFilesUploaded = false;

  // Server fields require for payload
  _id = "";
  region_id = "";
  site_id = "";
  region = [];
  cluster = [];
  site = [];
  start_date = "";
  end_date = "";
  user = "";
  auditor_email = "";
  auditor_phone = "";
  inspectorPhone = "";
  form_name = "";
  user_id = ""; // Current Logged In user
  due_date = Date.now();
  completed_date = "";
  created_at = "";
  is_completed = "";
  tasks = "";
  updated_at = "";
  submission_id = null;

  sites = [];
  clusters = [];
  totalSites = 0;
  clusterLoading = false;
  siteLoading = false;

  tasks = [];

  draft = null;
  isEdit = false;
  steperForm = steperForm;
  dynamicForms = [];
  isLoadingSteps = false;
  meta_data = {};
  get_all= false;
  siteAvailablityStatus = true;
  siteAvailablityMsg = '';

  constructor(props, task) {
    this.isEdit = !!props;
    if (this.isEdit) this.init(props);
    this.getDynamicForm();
    makeAutoObservable(this);
  }

  init(props) {
    if (props) {
      //   this.setPayload(props);
    }
  }

  setAttribute(name, value) {
    if (name) {
      this[name] = value;
    }
  }

  payloadSetAttribute(name, value) {
    if (name) {
      this[name] = value;
    }
  }

  setFormName = async (form_name) => {
    this.form_name = form_name;
  };

  clearInspectorsSteps = () => {
    this.tasks.forEach((task) => task.clearSteps());
  };

  setRegion(value) {
    this.region = value;
  }

  setCluster(value) {
    this.cluster = value;
  }

  setSite(value) {
    this.site = value;
  }
  setUserId(user) {
    this.user_id = user._id;
    this.user = user;
  }

  setValidation(check) {
    this.isValidation = check;
  }

  setIsSubmit(check) {
    this.isSubmit = check;
  }

  setIsReview(check) {
    this.isReview = check;
  }

  getPayload() {
    return {
      form_name: this.form_name.name,
      region_ids: this.region.map((region) => region._id),
      cluster_ids: this.cluster.map((cluster) => cluster._id),
      site_ids: this.site?.map((site) => site._id),
      start_date: moment(this.start_date).format("ddd MMM DD YYYY"),
      end_date: moment(this.end_date).format("ddd MMM DD YYYY"),
      meta_data: {
        form_id: this.form_name._id,
        name: this.form_name.name,
        slug: this.form_name.name,
        isDynamicForm: true,
      },
    };
  }

  getDynamicForm = () => {
    ModuleService.getDynamicForms().then((forms) => {
      this.dynamicForms.push(
        ...forms
          .filter((form) => form.status)
          .map((f) => new DynamicFormModal(f))
      );
    });
  };

  isValid = () => {
    let check =
      this.form_name &&
      this.region &&
      // this.cluster &&
      this.start_date &&
      this.end_date;

    return check;
  };

  review = () => {
    let check = this.isValid();
    if (!check) {
      this.isValidation = true;
      toast.error(IntlService.m("Please fill all the required fields!"));
      return;
    }

    this.setIsReview(true);
    this.isValidation = false;
    this.test = !this.test;
  };

  onStepPress = ({ stepNumber }) => {
    this.stepper.to(stepNumber);
    this.test = !this.test;
  };

  getClusters = () => {
    const ids = this.region.map((region) => region._id);
    if (ids?.length) {
      this.clusterLoading = true;
      this.clusters = [];
      this.sites = [];
      this.cluster = null;
      this.site = null;
      ClusterService.getClustersByRegionID(ids)
        .then((res) => {
          this.clusters = res;
          this.clusterLoading = false;
        })
        .catch(() => {
          this.clusterLoading = false;
        });
    }
  };

  getAvailableSites = (search = "") => {
    const params = {
      form_name: this.form_name?.name,
      start_date: this.start_date?.toDateString?.(),
      end_date: this.end_date?.toDateString?.(),
      region_ids: this.region?.map((region) => region._id) || [],
      cluster_ids: this.cluster?.map((cluster) => cluster._id) || [],
      search,
      get_all: this.get_all
    };
    ScheduleSelfAssessmentServices.getAvailableSites(params)
      .then((res) => {
        const sites = res.data.map((item) => ({
          ...item,
          label: item.name,
          value: item._id,
        }));

        this.siteAvailablityStatus = res.status;
        this.siteAvailablityMsg = res.message;
        this.sites = sites;
        this.totalSites = res.total_sites;
      }).catch((err) => {
        console.log("🚀 ~ SecduleSelfAssessmentModal ~ .then ~ err:", err)
      })
  };

  getSites = (search = "") => {
    const ids = this.cluster.map((cluster) => cluster._id);
    if (ids.length) {
      this.siteLoading = true;
      this.sites = [];
      const ids = this.cluster.map((cluster) => cluster._id);
      const module = this.form_name?.name;
      const params = {
        clusterId: ids,
        module,
        search,
        start_date: this.start_date?.toDateString?.(),
        end_date: this.end_date?.toDateString?.(),
      };
      SiteService.getSitesByClusterIDs(params)
        .then((res) => {
          this.sites = res.sites;
          this.totalSites = res.totalSites;
        })
        .finally(() => {
          this.siteLoading = false;
        });
    }
  };

  onSearch = debounce((search) => {
    this.getAvailableSites(search);
  }, 1000);

  get isAllSitesSelected() {
    return this.site.length === this.sites.length;
  }
}
