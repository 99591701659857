import moment from "moment";

const { Schema } = require("@formily/react");
const XLSX = require("xlsx");

class ExcelService {
  allHeaders = [];
  extractedData = [];
  data = [];
  schema = {};

  sortId = 0;

  stats = [];
  totals = [];
  form = {};

  constructor(form_id, schema, data, form) {
    this.schema = schema;
    this.data = data;
    this.form = form;

    this.extractedData = data
      .map(({ data, user, report_no, inspection_type, created_at }, index) => {
        return Object.entries(data)
          .filter(([keuy, value]) => Array.isArray(value) && value?.[0]?.step)
          .map(([key, value]) => value)
          .flat()
          .map((question) => {
            let otherCols = {};

            if (form_id === "66404a888fc238b1660689cc") {
              otherCols["Country"] = data?.country?.label;
              otherCols["Branch"] = data?.branch_number?.value;
              otherCols["Mission Name"] = data?.["0zy0ynd4w0h"];
            }

            let resp = {
              ["#No"]: index + 1,
              ["Report No"]: report_no,
              ["Region"]: data?.region?.label,
              ["Cluster"]: data?.cluster?.label,
              ...otherCols,
              ["Site"]: data?.site?.label,
              ["Inspection Type"]: inspection_type,
              ["Step Name"]: question.step,
              ["Question Number"]: question.no,
              ["Standard"]: question.title,
              ["Answer"]: question.question,
              ["User Name"]: user?.name,
              ["User Email"]: user?.email,
              ["Submission Date"]: moment(created_at).format("ll"),
            };

            if (form_id === "66404a888fc238b1660689cc") {
              delete resp["Site"];
            }

            return resp;
          });
      })
      .flat();

    const regionCounts = {};
    let complyCount = 0; // Initialize count for Answer = "Comply"
    let notComplyCount = 0; // Initialize count for Answer = "Comply"
    let naCount = 0; // Initialize count for Answer = "Comply"

    this.extractedData.forEach((item) => {
      const region = item["Region"];
      const answer = item["Answer"];

      // Count unique regions
      if (!regionCounts[region]) {
        regionCounts[region] = {
          users: new Set(),
          comply: 0,
          notComply: 0,
          na: 0,
        };
      }

      regionCounts[region].users.add(item["User Name"]);

      // Increment counts based on answer
      if (answer === "Comply") {
        regionCounts[region].comply++;
        complyCount++;
      } else if (answer === "Not Comply") {
        regionCounts[region].notComply++;
        notComplyCount++;
      } else if (answer === "N/A") {
        regionCounts[region].na++;
        naCount++;
      }
    });

    this.stats = Object.entries(regionCounts).map(([region, value]) => {
      return {
        Region: region,
        "Total Users": value.users.size,
        Comply: value.comply,
        "Not Comply": value.notComply,
        "Not Applicable": value.na,
      };
    });

    let totalInspections = this.data.filter(
      ({ inspection_type }) => inspection_type !== "Self assessment"
    ).length;

    this.totals = [
      {
        ["Total Submissions"]: this.data.length,
        ["Total Inspections"]: totalInspections,

        ["Total Self Assessments"]: this.data.length - totalInspections,
      },
    ];

    console.log(this.schema);
  }

  get uniqueHeaders() {
    return this.allHeaders
      .filter(
        (obj, index, array) =>
          index === array.findIndex((item) => item.header === obj.header)
      )
      .sort((a, b) => a.sortId - b.sortId)
      .map((item) => item.header.replaceAll("\n", ""));
  }

  // Generate Excel file
  generateExcel() {
    const worksheet = XLSX.utils.json_to_sheet(this.extractedData);
    const worksheet2 = XLSX.utils.json_to_sheet(this.stats);
    const worksheet3 = XLSX.utils.json_to_sheet(this.totals);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Submission");
    XLSX.utils.book_append_sheet(workbook, worksheet2, "Stats");
    XLSX.utils.book_append_sheet(workbook, worksheet3, "Totals");
    XLSX.writeFile(
      workbook,
      `${this.form.name} - ${moment().format("llll")}.${"xlsx"}`
    );
  }
}

export default ExcelService;
