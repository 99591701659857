import { Singleton, Resources } from "../Resource";
import { SC } from "../Api/serverCall";

class Service extends Resources {
  route = "medicine";

  constructor() {
    super(arguments);
  }

  getIrsMedicine(params) {
    return SC.getCall({
      url: "get-irs-medicine",
      params,
    });
  }

  getHeaders() {
    return [
      { title: "Name", dataIndex: "name" },
      { title: "Generic Code", dataIndex: "genericCode" },
      { title: "Created at", dataIndex: "created_at" },
    ];
  }

  mapExportData(data) {
    return {
      headers: this.getHeaders(),
      data,
    };
  }
}

export default Singleton(Service);
